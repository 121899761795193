import React from "react";

import styles from "./OverallExpectedResults.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";

interface OverallExpectedResultsProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const OverallExpectedResults: React.FC<OverallExpectedResultsProps> = (
  props
) => {
  const { className, cardType, dummyLoaderDuration } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    nurtureStrategyData &&
      dispatch(
        setNurtureStrategyData({
          ...nurtureStrategyData,
          overallExpectedResults: isPinned,
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={dummyLoaderDuration}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Overall Expected Results
        </TextElement>
      </div>
      <div className={styles["content"]}>
        <ul>
          <li>
            <span className={styles["highlight"]}>
              Engagement Rate Increase:
            </span>{" "}
            Aim To{" "}
            <span className={styles["highlight"]}>
              improve overall engagement by 25%
            </span>{" "}
            through personalized and relevant content.
          </li>
          <li>
            <span className={styles["highlight"]}>SQL Conversion Rate:</span>
            Target a <span className={styles["highlight"]}>
              30% increase
            </span>{" "}
            in MQL to SQL conversion rate.
          </li>
          <li>
            <span className={styles["highlight"]}>Customer Feedback Loop:</span>{" "}
            Implement feedback mechanisms after each content distribution to
            continuously refine and improve strategies.
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default OverallExpectedResults;
