import React, { useState, useEffect } from "react";

import styles from "./CampaignData.module.scss";

import Tabs from "../../componenets/Tabs/Tabs";
import { Button, TextElement } from "../../componenets";
import MarketingBrief from "./componenets/MarketingBrief/MarketingBrief";
import ShowCampaignContentData from "../../componenets/ShowCampaignContentData/ShowCampaignContentData";
import ShowCampaignAnalysisData from "../../componenets/ShowCampaignAnalysisData/ShowCampaignAnalysisData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import AddCommentIcon from "../../icons/AddCommentIcon";
import classNames from "classnames";
import AddApproverCommentModal from "../../componenets/AddApproverCommentModal/AddApproverCommentModal";
import ApproverCommentChat from "../../componenets/ApproverCommentChat/ApproverCommentChat";
import ApprovedIcon from "../../icons/ApprovedIcon";
import {
  createCommentPoint,
  fetchCommentPoints,
} from "../../redux/campaignCommentsSlice";
import ApproveCampaignModal from "../../componenets/ApproveCampaignModal/ApproveCampaignModal";
import NinjaResponseModal from "../../componenets/NinjaResponseModal/NinjaResponseModal";
import useCampaignData from "./useCampaignData";
import {
  CampaignStatusType,
  fetchCampaigns,
} from "../../redux/campaignPinningSlice";

type Props = {};

const ApproverCampaignData: React.FC<Props> = (props) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const navigate = useNavigate();

  const [campaignActiveTab, setCampaingActiveTab] = useState(1);
  const [isCommentingActive, setIsCommentingActive] = useState(false);
  const [isAddCommentOpen, setIsAddCommentOpen] = useState(false);
  const [commentModalPosition, setCommentModalPosition] =
    useState<React.CSSProperties>();
  const dispatch = useAppDispatch();
  const { loggedInUser } = useAppSelector((state) => state.auth);
  const { campaignComments } = useAppSelector(
    (state) => state.campaignComments
  );

  const [isCampaignApprovalModalOpen, setIsCampaignApprovalModalOpen] =
    useState(false);
  const [changeCampaignStatusModalOpen, setChangeCampaignStatusModalOpen] =
    useState(false);

  const { changeCampaignStatus } = useCampaignData();

  useEffect(() => {
    setCampaingActiveTab(
      parseInt(new URLSearchParams(window.location.search).get("tab") || "1")
    );
  }, [window.location.search]);

  useEffect(() => {
    if (selectedCampaign)
      dispatch(
        fetchCommentPoints({
          campaign_plan_id: selectedCampaign.id,
        })
      );
  }, [selectedCampaign]);

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Marketing Brief",
      content: <MarketingBrief className={classNames(styles["tab-content"])} />,
    },
    {
      id: 2,
      label: "Analysis",
      content: (
        <ShowCampaignAnalysisData
          className={classNames(styles["tab-content"])}
        />
      ),
    },
    {
      id: 3,
      label: "Content",
      content: (
        <ShowCampaignContentData
          className={classNames(styles["tab-content"])}
        />
      ),
    },
    // {
    //   id: 4,
    //   label: "Performance",
    //   content: (
    //     <MarketingReport className={classNames(styles["tab-content"])} />
    //   ),
    // },
  ]);
  return (
    <>
      {selectedCampaign &&
        campaignComments &&
        campaignComments
          ?.filter((campaignComment) => {
            if (
              campaignActiveTab === 1 ||
              campaignActiveTab === 4 ||
              campaignComment?.comments?.length === 0
            )
              return false;
            return (
              campaignComment?.type ===
              (campaignActiveTab === 2 ? "analysis" : "content")
            );
          })
          .map((commentPoint, index) => {
            return (
              <ApproverCommentChat
                key={index}
                comments={commentPoint.comments}
                commentPointId={commentPoint.commentPointId}
                style={commentPoint.style}
              />
            );
          })} 
      <ApproverViewLayout
        header={
          <div className={styles["header"]}>
            <a
              onClick={() =>
                navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGINS_LIST.path)
              }
            >
              Back to My Campaigns
            </a>
            <TextElement>
              {selectedCampaign?.title || "Campaign Name"}
            </TextElement>
          </div>
        }
      >
        <div className={styles["tabs-container"]}>
          <AddApproverCommentModal
            isOpen={isAddCommentOpen}
            closeModal={() => setIsAddCommentOpen(false)}
            onOk={(commentText, style) => {
              loggedInUser &&
                selectedCampaign &&
                dispatch(
                  createCommentPoint({
                    type: campaignActiveTab === 2 ? "analysis" : "content",
                    comments: [
                      {
                        text: commentText,
                        user: loggedInUser.userid,
                        profileImage:
                          "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
                      },
                    ],
                    campaignPlan: selectedCampaign.id,
                    style,
                  })
                );
              setIsAddCommentOpen(false);
            }}
            style={
              commentModalPosition
                ? {
                    left: commentModalPosition.left,
                    top: commentModalPosition.top,
                  }
                : undefined
            }
          />

          <ApproveCampaignModal
            isOpen={isCampaignApprovalModalOpen}
            closeModal={() => setIsCampaignApprovalModalOpen(false)}
            onOk={() => {
              selectedCampaign &&
                changeCampaignStatus(
                  selectedCampaign.id,
                  CampaignStatusType.APPROVED
                ).then((response) => {
                  if (response?.status === CampaignStatusType.APPROVED) {
                    setChangeCampaignStatusModalOpen(true);
                    setTimeout(() => {
                      setChangeCampaignStatusModalOpen(false);
                    }, 2000);
                    dispatch(fetchCampaigns());
                  }
                });
              setIsCampaignApprovalModalOpen(false);
            }}
            campaignName={selectedCampaign?.title || "Campaign Name"}
          />

          <NinjaResponseModal
            isOpen={changeCampaignStatusModalOpen}
            closeModal={() => setChangeCampaignStatusModalOpen(false)}
            message={"successfully approved!"}
            heading={selectedCampaign?.title || "Campaign Name"}
          />

          <Tabs
            className={styles["tabs"]}
            tabs={tabs}
            basePath={ROUTE_DEFINITIONS.APPROVER_CAMPAGIN_DATA.path}
            tabContentClassName={classNames(
              (styles["tab-content"],
              isCommentingActive ? styles["comment-active-tab-content"] : "")
            )}
            onTabContentClick={(e) => {
              if (isCommentingActive) {
                const newPosition = {
                  left: ((e.pageX / window.innerWidth) * 100).toFixed(1) + "%",
                  top: ((e.pageY / window.innerHeight) * 100).toFixed(1) + "%",
                };
                setCommentModalPosition(newPosition);
                setIsAddCommentOpen(true);
              }
              e.stopPropagation();
            }}
          />
          {(campaignActiveTab == 2 || campaignActiveTab == 3) && (
            <div className={styles["actions"]}>
              <Button
                className={styles["approve-btn"]}
                onClick={() => {
                  if (selectedCampaign?.status === CampaignStatusType.APPROVED)
                    return;
                  setIsCampaignApprovalModalOpen(true);
                }}
              > 
                <ApprovedIcon />
                <TextElement>
                  {selectedCampaign?.status === CampaignStatusType.APPROVED
                    ? "Approved"
                    : "Approve"}
                </TextElement>
              </Button>
              <Button
                variant="outline"
                className={classNames(
                  styles["comment-btn"],
                  isCommentingActive ? styles["comment-enable-btn"] : ""
                )}
                onClick={() => setIsCommentingActive(!isCommentingActive)}
              >
                <AddCommentIcon />
                <TextElement> Add a Comment</TextElement>
              </Button>
            </div>
          )}
        </div>
      </ApproverViewLayout>
    </>
  );
};

export default ApproverCampaignData;
