import React, { useEffect } from "react";

import styles from "./ShowCampaignAnalysisData.module.scss";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DisclosureComponent from "../Disclosure/Disclosure";
import TextElement from "../TextElement/TextElement";
import useCampaignQueryChat from "../CampaignQueryChat/useCampaignQueryChat";
import MarketSentimentCard from "../MarketSentimentCard/MarketSentimentCard";
import CompetitorStrategies from "../CompetitorStrategies/CompetitorStrategies";
import classNames from "classnames";
import TargetAudienceAndEstimatedNumbersCard from "../TargetAudienceAndEstimatedNumbersCard/TargetAudienceAndEstimatedNumbersCard";
import Recommended5GOfferingsCard from "../Recommended5GOfferingsCard/Recommended5GOfferingsCard";
import RecommendedMarketingChannels from "../RecommendedMarketingChannels/RecommendedMarketingChannels";
import BudgetAllocationAndBreakdown from "../BudgetAllocationAndBreakdown/BudgetAllocationAndBreakdown";
import ProjectedROICard from "../ProjectedROICard/ProjectedROICard";
import PerformanceMetricsCard from "../PerformanceMetricsCard/PerformanceMetricsCard";
import AverageChannelPerformance from "../AverageChannelPerformance/AverageChannelPerformance";
import useCampaignAnalysisData, {
  CampaignAnalysisType,
} from "./useCampaignAnalysisData";
import AudienceSegmentationModal from "../AudienceSegmentationModal/AudienceSegmentationModal";
import CompetitorAnalysisModal from "../CompetitorAnalysisModal/CompetitorAnalysisModal";
import AudienceSegmentation from "../AudienceSegmentationCards/AudienceSegmentation";
import {
  fetchBudgetAllocationForDifferentChannels,
  fetchPastCampaignAnalysis,
  fetchSegments,
} from "../../redux/campaignPlanAnalysisQueryChat";
import ShowPastInsights from "./componenets/ShowPastInsights";
import ShowAudienceSegments from "./componenets/ShowAudienceSegments";

interface Props {
  className?: string;
  onClick?: () => void;
}

const ShowCampaignAnalysisData: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();
  const {
    hasAudienceSegmentation,
    hasCompetitorAnalysis,
    hasPastInsights,
    hasCampaignRecommendations,
    hasMarketSentiments,
  } = useCampaignAnalysisData();

  const [competitorAnalysisModal, setCompetitorAnalysisModal] =
    React.useState(false);

  return (
    <div className={classNames(styles["content"], className)} onClick={onClick}>
      <CompetitorAnalysisModal
        isOpen={competitorAnalysisModal}
        closeModal={() => setCompetitorAnalysisModal(false)}
      />
      {hasPastInsights && (
        <DisclosureComponent
          header={<TextElement>Insights from past campaigns</TextElement>}
          content={<ShowPastInsights />}
        />
      )}

      {hasAudienceSegmentation && (
        <DisclosureComponent
          header={<TextElement>Audience Segmentation</TextElement>}
          content={<ShowAudienceSegments />}
        />
      )}

      {hasMarketSentiments && (
        <DisclosureComponent
          header={<TextElement>Market Sentiments</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {selectedCampaign?.includedData?.marketSentimentIds.map(
                (sentimentId, index) => (
                  <MarketSentimentCard
                    id={sentimentId}
                    cardType="ellipses"
                    key={index}
                  />
                )
              )}
            </div>
          }
        />
      )}

      {hasCompetitorAnalysis && (
        <DisclosureComponent
          header={<TextElement>Competitor Analysis</TextElement>}
          content={
            <>
              <div className={styles["campaign-data"]}>
                {selectedCampaign?.includedData &&
                  selectedCampaign.includedData.competitorStrategies && (
                    <CompetitorStrategies cardType="ellipses" />
                  )}
              </div>
              <footer className={styles["disclosure-footer"]}>
                <a
                  className={styles["underline-text"]}
                  onClick={() => setCompetitorAnalysisModal(true)}
                >
                  See comparison details
                </a>
              </footer>
            </>
          }
        />
      )}

      {hasCampaignRecommendations && (
        <DisclosureComponent
          header={<TextElement>Campaign Recommendations</TextElement>}
          content={
            <div
              className={classNames(
                styles["campaign-data"],
                styles["recommendation-data"]
              )}
            >
              {selectedCampaign?.includedData?.recommendations
                ?.targetAudienceAndEstimatedNumbers && (
                <TargetAudienceAndEstimatedNumbersCard cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedOfferings && (
                <Recommended5GOfferingsCard cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedChannels && (
                <RecommendedMarketingChannels cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.recommendedBudget && (
                <BudgetAllocationAndBreakdown cardType="ellipses" />
              )}
              {selectedCampaign?.includedData?.recommendations
                ?.projectedRoi && <ProjectedROICard cardType="ellipses" />}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ShowCampaignAnalysisData;
