import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchBudgetAllocationForDifferentChannels,
  fetchPastCampaignAnalysis,
  FetchPastCampaignAnalysisResponse,
  fetchSegments,
} from "../redux/campaignPlanAnalysisQueryChat";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CampaignAnalysisQueryChatContextType {
  conversation: Question[];
  updateConversation: (
    newConversation: Question[],
    startAgain?: boolean
  ) => void;
  clearConversation: () => void;
  fetchSegmentsWithCallback: (
    successCallback: () => void,
    errorCallback: () => void
  ) => void;
  fetchPastCampaignAnalysisWithCallback: (
    successCallback: (response: FetchPastCampaignAnalysisResponse) => void,
    errorCallback: () => void
  ) => void;
  fetchBudgetAllocationForDifferentChannelsWithCallback: (
    successCallback: () => void,
    errorCallback: () => void
  ) => void;
}

const CampaignAnalysisQueryChatContext = createContext<
  CampaignAnalysisQueryChatContextType | undefined
>(undefined);

export const CampaignAnalysisQueryChatProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [conversation, setConversation] = useState<Question[]>([]);
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const selectedCampaingRef = React.useRef(selectedCampaign);

  useEffect(() => {
    selectedCampaingRef.current = selectedCampaign;
  }, [selectedCampaign]);

  const fetchSegmentsWithCallback = async (
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    selectedCampaingRef.current &&
      dispatch(
        fetchSegments({
          campaignPlanId: selectedCampaingRef.current?.id,
        })
      )
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
  };

  const fetchPastCampaignAnalysisWithCallback = async (
    successCallback: (response: FetchPastCampaignAnalysisResponse) => void,
    errorCallback: () => void
  ) => {
    selectedCampaingRef.current &&
      dispatch(
        fetchPastCampaignAnalysis({
          campaignPlanId: selectedCampaingRef.current?.id,
        })
      )
        .then((response) => {
          successCallback(
            response.payload as FetchPastCampaignAnalysisResponse
          );
        })
        .catch(() => {
          errorCallback();
        });
  };

  const fetchBudgetAllocationForDifferentChannelsWithCallback = async (
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    selectedCampaingRef?.current &&
      dispatch(
        fetchBudgetAllocationForDifferentChannels({
          campaignPlanId: selectedCampaingRef.current?.id,
        })
      )
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
  };

  const updateConversation = (
    newConversation: Question[],
    startAgain?: boolean
  ) => {
    if (startAgain) {
      setConversation([...newConversation]);
    } else {
      setConversation((prevConversation) => {
        return [...newConversation, ...prevConversation];
      });
    }
  };

  const clearConversation = () => {
    setConversation([]);
  };

  return (
    <CampaignAnalysisQueryChatContext.Provider
      value={{
        conversation,
        updateConversation,
        clearConversation,
        fetchSegmentsWithCallback,
        fetchPastCampaignAnalysisWithCallback,
        fetchBudgetAllocationForDifferentChannelsWithCallback,
      }}
    >
      {children}
    </CampaignAnalysisQueryChatContext.Provider>
  );
};

export const useCampaignAnalysisQueryChat =
  (): CampaignAnalysisQueryChatContextType => {
    const context = useContext(CampaignAnalysisQueryChatContext);
    if (!context) {
      throw new Error(
        "useCampaignAnalysisQueryChat must be used within a CampaignAnalysisQueryChatProvider"
      );
    }
    return context;
  };
