import React from "react";

const FocusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="45"
      height="40"
      viewBox="0 0 45 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.2222 20.4889C42.2222 8.28889 32.7556 0 22.2222 0C11.8 0 2.22222 8.11111 2.22222 20.6222C0.888889 21.3778 0 22.8 0 24.4444V28.8889C0 31.3333 2 33.3333 4.44444 33.3333H6.66667V19.7778C6.66667 11.1778 13.6222 4.22222 22.2222 4.22222C30.8222 4.22222 37.7778 11.1778 37.7778 19.7778V35.5556H20V40H37.7778C40.2222 40 42.2222 38 42.2222 35.5556V32.8444C43.5333 32.1556 44.4444 30.8 44.4444 29.2V24.0889C44.4444 22.5333 43.5333 21.1778 42.2222 20.4889Z"
        fill="#EB2701"
      />
      <path
        d="M15.5552 24.4444C16.7825 24.4444 17.7775 23.4495 17.7775 22.2222C17.7775 20.9949 16.7825 20 15.5552 20C14.3279 20 13.333 20.9949 13.333 22.2222C13.333 23.4495 14.3279 24.4444 15.5552 24.4444Z"
        fill="#EB2701"
      />
      <path
        d="M28.8892 24.4444C30.1165 24.4444 31.1114 23.4495 31.1114 22.2222C31.1114 20.9949 30.1165 20 28.8892 20C27.6619 20 26.667 20.9949 26.667 22.2222C26.667 23.4495 27.6619 24.4444 28.8892 24.4444Z"
        fill="#EB2701"
      />
      <path
        d="M35.5551 17.8445C34.4884 11.5112 28.9773 6.66675 22.3328 6.66675C15.5995 6.66675 8.35505 12.2445 8.93283 21.0001C14.4217 18.7556 18.5551 13.8667 19.7328 7.91119C22.6439 13.7556 28.6217 17.7779 35.5551 17.8445Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default FocusIcon;
