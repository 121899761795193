import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./Input.module.scss";
import Skeleton from "react-loading-skeleton";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode | string;
  value: string;
  validator?: (value: string) => string | null;
  inputClassName?: string;
  className?: string;
  loading?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  value,
  onChange,
  validator,
  onInput,
  inputClassName,
  className,
  loading,
  ...rest
}) => {
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validator === undefined) {
      setInputValue(event.target.value);
      if (onChange) onChange(event);
      if (onInput) onInput(event);
      return;
    }
    const inputValue = event.target.value;
    const errorText = validator(inputValue);
    setError(errorText);
    setInputValue(inputValue);
    if (!errorText && onChange) onChange(event);
    if (!errorText && onInput) onInput(event);
  };

  return (
    <div className={classNames(styles["container"], className)}>
      <label>{label}</label>
      {loading ? (
        <Skeleton className={styles['input-skeleton']} />
      ) : (
        <input
          value={inputValue}
          className={classNames(styles["input"], inputClassName)}
          onChange={handleInputChange}
          {...rest}
        />
      )}

      {error && <span className={styles["error"]}>{error}</span>}
    </div>
  );
};

export default Input;
