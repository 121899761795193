import { configureStore } from "@reduxjs/toolkit";
import marketingBriefSliceReducer from "./marketingBriefSlice";
import campaignPinningSlice from "./campaignPinningSlice";
import authSlice from "./authSlice";
import campaignPlanAnalysisQueryChat from "./campaignPlanAnalysisQueryChat";
import teamMemberSlice from "./teamMemberSlice";
import campaignCommentsSlice from "./campaignCommentsSlice";
import interestStageSlice from "./interestStageSlice";
import notificationSlice from "./notificationsSlice";

export const store = configureStore({
  reducer: {
    marketingBrief: marketingBriefSliceReducer,
    campaignData: campaignPinningSlice,
    auth: authSlice,
    campaignPlanAnalysisQueryChat,
    teamMember: teamMemberSlice,
    campaignComments: campaignCommentsSlice,
    interestStage: interestStageSlice,
    notifications: notificationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
