import React from "react";

const PartnershipIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="45"
      height="40"
      viewBox="0 0 45 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.4592 35.7179C22.099 36.078 21.4587 36.1381 21.0385 35.7179C20.6783 35.3577 20.6183 34.7174 21.0385 34.2971L27.8219 27.5138L25.0005 24.6923L18.2171 31.4757C17.8369 31.8759 17.1966 31.8559 16.7964 31.4757C16.3762 31.0555 16.4362 30.4152 16.7964 30.055L23.5798 23.2716L20.7584 20.4502L13.975 27.2336C13.6148 27.5938 12.9745 27.6538 12.5543 27.2336C12.1741 26.8534 12.1741 26.2131 12.5543 25.8129L19.3377 19.0295L16.4962 16.2081L9.71285 22.9915C9.35267 23.3517 8.71235 23.4117 8.29214 22.9915C7.91195 22.5913 7.91195 21.971 8.29214 21.5708L17.0565 12.8064L20.7984 16.5283C22.6993 18.4292 25.981 18.4092 27.8819 16.5283C29.8429 14.5673 29.8429 11.4057 27.8819 9.44472L24.1601 5.72286L24.7204 5.16258C26.2811 3.6018 28.8224 3.6018 30.3832 5.16258L38.8674 13.6468C40.4282 15.2076 40.4282 17.7489 38.8674 19.3097L22.4592 35.7179ZM41.6888 22.1511C44.8104 19.0295 44.8104 13.967 41.6888 10.8254L33.2046 2.34117C30.083 -0.78039 25.0205 -0.78039 21.8789 2.34117L21.3187 2.90145L20.7584 2.34117C17.6368 -0.78039 12.5743 -0.78039 9.43271 2.34117L2.34917 9.42471C-0.49225 12.2661 -0.75238 16.6883 1.54877 19.8099L4.45022 16.9085C3.66983 15.4077 3.92996 13.5068 5.19059 12.2461L12.2741 5.16258C13.8349 3.6018 16.3762 3.6018 17.937 5.16258L25.0605 12.2861C25.4207 12.6463 25.4807 13.2866 25.0605 13.7069C24.6403 14.1271 24 14.067 23.6398 13.7069L17.0565 7.14357L5.45072 18.7294C3.48974 20.6703 3.48974 23.8519 5.45072 25.8129C6.23111 26.5933 7.23161 27.0735 8.29214 27.2136C8.43221 28.2541 8.89244 29.2546 9.69284 30.055C10.4932 30.8554 11.4937 31.3157 12.5343 31.4557C12.6743 32.4962 13.1346 33.4967 13.935 34.2971C14.7354 35.0975 15.7359 35.5578 16.7764 35.6978C16.9165 36.7784 17.3967 37.7589 18.1771 38.5393C19.1176 39.4797 20.3782 40 21.7189 40C23.0595 40 24.3202 39.4797 25.2606 38.5393L41.6888 22.1511Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default PartnershipIcon;
