import React, { useEffect } from "react";

import styles from "./BudgetAllocationAndBreakdown.module.scss";
import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Table from "./Table/Table";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const BudgetAllocationAndBreakdown: React.FC<Props> = (props) => {
  const { className, dummyLoaderDuration, cardType } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { recommendation } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign &&
      selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              recommendedBudget: isPinned,
            },
          },
        })
      );
  };

  useEffect(() => {});

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.recommendation ? undefined : dummyLoaderDuration
      }
      type={cardType}
    >
      {recommendation?.budgetAllocations?.map((budgetAllocation) => {
        return (
          <>
            <TextElement className={styles["sub-heading"]}>
              {budgetAllocation?.title}{" "}
              <span>
                ($
                {budgetAllocation["budget allocation"].reduce(
                  (acc, curr) => acc + curr.spend,
                  0
                )}
                )
              </span>
            </TextElement>
            <Table
              columns={[
                {
                  header: "Channel/Activity",
                  accessor: "channel",
                },
                {
                  header: "Spend",
                  accessor: "spend",
                },
                {
                  header: "Projected ROI",
                  accessor: "roi",
                },
              ]}
              rows={
                budgetAllocation["budget allocation"]?.map((allocation) => {
                  return {
                    channel: allocation.channel,
                    spend: `$${allocation.spend}`,
                    roi: `$${allocation["projected ROI"]}`,
                  };
                }) || []
              }
            />
          </>
        );
      })}
    </Card>
  );
};

export default BudgetAllocationAndBreakdown;
