import React from "react";

import styles from "./EmailMarketingContentCard.module.scss";

import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface EmailMarketingContentCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const EmailMarketingContentCard: React.FC<EmailMarketingContentCardProps> = (
  props
) => {
  const { className, cardType, dummyLoaderDuration } = props;

  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const handlePinned = (isPinned: boolean) => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            content: {
              ...selectedCampaign?.includedData?.content,
              emailMarketing: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={dummyLoaderDuration}
    >
      <TextElement className={styles["heading"]}>Email Marketing</TextElement>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Subject Line
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Supercharge Your Business with 5G Technology
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Subject Line
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Dear [Recipient],
            <br />
            Explore the practical benefits of integrating 5G technology into
            your business operations. Our latest blog post, "The Future is Here:
            How 5G Can Transform Your Business," delves into the ways in which
            5G can enhance efficiency and reduce costs, fostering innovation and
            expansion. <br />
            <br />
            With speeds surpassing 4G by up to 10 times and minimal latency, 5G
            offers businesses across sectors an array of opportunities.
            Experience swift data transfers and real-time communication, bidding
            farewell to delays and sluggish downloads. <br />
            <br />
            Keen to harness the advantages of 5G? Click the link below to delve
            into our blog post and discover how this technology can elevate your
            organization.
            <br />
            <br /> [Read More] Stay proactive and embrace the future with 5G
            technology.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default EmailMarketingContentCard;
