import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

enum NotificationsType {
  CAMPAIGN_PLAN_STATUS_CHANGE = "CPSC",
  CAMPAIGN_LAUNCHED = "CL",
  CAMPAIGN_PLAN_COMMENT = "CPC",
}

export interface NotificationFields {
  id: number;
  type: NotificationsType;
  campaignId: number;
  campaignPlanId: number;
  commentId: number;
  commentPointId: number;
  message: string;
  isRead: boolean;
  user: number;
}

interface State {
  notifications: NotificationFields[];
  unreadCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  notifications: [],
  unreadCount: 0,
  loading: false,
  error: null,
};

const httpService = new HttpService();

export const fetchNotifications = createAsyncThunk<NotificationFields[], void>(
  "notifications/fetchNotifications",
  async (): Promise<NotificationFields[]> => {
    const response = await httpService.get<NotificationFields[]>(
      "api/notifications/"
    );
    return response.data;
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.unreadCount = action.payload.filter((n) => !n.isRead).length;
        state.loading = false;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.error = action.error.message || "An error occurred";
        state.loading = false;
      });
  },
});

export const {} = notificationsSlice.actions;
export default notificationsSlice.reducer;
