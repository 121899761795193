import React, { useState, useEffect } from "react";

import styles from "./CampaignData.module.scss";

import Tabs from "../../componenets/Tabs/Tabs";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import MarketingBrief from "./componenets/MarketingBrief/MarketingBrief";
import ShowCampaignContentData from "../../componenets/ShowCampaignContentData/ShowCampaignContentData";
import ShowCampaignAnalysisData from "../../componenets/ShowCampaignAnalysisData/ShowCampaignAnalysisData";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import AddCommentIcon from "../../icons/AddCommentIcon";
import CommentsSideBar from "../../componenets/CommentsSideBar/CommentsSideBar";
import MarketingReport from "../../componenets/CampaignReports/MarketingReport";
import ApproverCommentChat from "../../componenets/ApproverCommentChat/ApproverCommentChat";

type Props = {};

const MarketerCampaignData: React.FC<Props> = (props) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const navigate = useNavigate();
  const [campaignActiveTab, setCampaingActiveTab] = useState(1);
  const [commentSideBarOpen, setCommentSideBarOpen] = useState(false);

  const { campaignComments } = useAppSelector(
    (state) => state.campaignComments
  );

  useEffect(() => {
    setCampaingActiveTab(
      parseInt(new URLSearchParams(window.location.search).get("tab") || "1")
    );
  }, [window.location.search]);

  useEffect(() => {
    // console.log(campaignComments);
  }, [campaignComments]);

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Marketing Brief",
      content: <MarketingBrief className={styles["tab-content"]} />,
    },
    {
      id: 2,
      label: "Analysis",
      content: <ShowCampaignAnalysisData />,
    },
    {
      id: 3,
      label: "Content",
      content: <ShowCampaignContentData className={styles["tab-content"]} />,
    },
    // {
    //   id: 4,
    //   label: "Performance",
    //   content: <MarketingReport className={styles["tab-content"]} />,
    // },
  ]);

  return (
    <>
      {selectedCampaign &&
        campaignComments &&
        campaignComments
          ?.filter((campaignComment) => {
            if (
              campaignActiveTab === 1 ||
              campaignActiveTab === 4 ||
              campaignComment?.comments?.length === 0
            )
              return false;
            return (
              campaignComment?.type ===
              (campaignActiveTab === 2 ? "analysis" : "content")
            );
          })
          .map((commentPoint, index) => {
            return (
              <ApproverCommentChat
                key={index}
                comments={commentPoint.comments}
                commentPointId={commentPoint.commentPointId}
                style={commentPoint.style}
              />
            );
          })}
      <MarkerterViewLayout
        header={
          <div className={styles["header"]}>
            <a
              onClick={() =>
                navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGINS_LIST.path)
              }
            >
              Back to My Campaigns
            </a>
            <TextElement>
              {selectedCampaign?.title || "Campaign Name"}
            </TextElement>
          </div>
        }
      >
        <div className={styles["tabs-container"]}>
          {(campaignActiveTab == 2 || campaignActiveTab == 3) && (
            <CommentsSideBar
              open={commentSideBarOpen}
              onClose={() => setCommentSideBarOpen(false)}
            />
          )}
          <Tabs
            className={styles["tabs"]}
            tabs={tabs}
            basePath={ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_DATA.path}
          />
          {(campaignActiveTab == 2 || campaignActiveTab == 3) && (
            <div className={styles["actions"]}>
              <Button
                variant="outline"
                className={styles["comment-btn"]}
                onClick={() => setCommentSideBarOpen(true)}
              >
                <AddCommentIcon />
                <TextElement>Show comments</TextElement>
              </Button>
            </div>
          )}
        </div>
      </MarkerterViewLayout>
    </>
  );
};

export default MarketerCampaignData;
