import React from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";

import styles from "./ApprovalSubmittedCampaignModal.module.scss";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
}

const ApprovalSubmittedCampaignModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen } = props;

  return (
    <Modal
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      <div className={styles["header"]}>
        <div className={styles["success-container"]}>
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 0C7.616 0 0 7.616 0 17C0 26.384 7.616 34 17 34C26.384 34 34 26.384 34 17C34 7.616 26.384 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
              fill="#2BD05A"
            />
          </svg>
          <TextElement>Successfully sent for approval!</TextElement>
        </div>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={closeModal}
          className={styles["close-icon"]}
        >
          <path
            d="M6.575 7.975L1.675 12.875C1.49167 13.0583 1.25833 13.15 0.975 13.15C0.691667 13.15 0.458333 13.0583 0.275 12.875C0.0916663 12.6917 0 12.4583 0 12.175C0 11.8917 0.0916663 11.6583 0.275 11.475L5.175 6.575L0.275 1.675C0.0916663 1.49167 0 1.25833 0 0.975C0 0.691667 0.0916663 0.458333 0.275 0.275C0.458333 0.0916663 0.691667 0 0.975 0C1.25833 0 1.49167 0.0916663 1.675 0.275L6.575 5.175L11.475 0.275C11.6583 0.0916663 11.8917 0 12.175 0C12.4583 0 12.6917 0.0916663 12.875 0.275C13.0583 0.458333 13.15 0.691667 13.15 0.975C13.15 1.25833 13.0583 1.49167 12.875 1.675L7.975 6.575L12.875 11.475C13.0583 11.6583 13.15 11.8917 13.15 12.175C13.15 12.4583 13.0583 12.6917 12.875 12.875C12.6917 13.0583 12.4583 13.15 12.175 13.15C11.8917 13.15 11.6583 13.0583 11.475 12.875L6.575 7.975Z"
            fill="black"
          />
        </svg>
      </div>
      <TextElement className={styles["subjective-text"]}>
        Your approver has been notified. You'll get a ping once your campaign
        gets the green light or if there are any comments. Keep planning your
        marketing or head back to your campaigns page.
      </TextElement>

      <Button className={styles["action-btn"]} onClick={closeModal}>
        Go to My Campaigns Plans
      </Button>
    </Modal>
  );
};

export default ApprovalSubmittedCampaignModal;
