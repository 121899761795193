import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import { toast } from "react-toastify";
import { CampaignStatusType } from "../../redux/campaignPinningSlice";

interface ChangeCampaignResponse {
  status: CampaignStatusType;
}

const useCampaignData = () => {
  const httpService = new HttpService();

  const changeCampaignStatus = async (campaignId: number, status: string) => {
    try {
      const response = await httpService.update<
        ChangeCampaignResponse,
        { status: string }
      >(`/api/campaign/${campaignId}/update-status/`, {
        status,
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to change campaign status");
      console.error("Failed to change campaign status", error);
      return null;
    }
  };

  return {
    changeCampaignStatus,
  };
};

export default useCampaignData;
