import React from "react";

import styles from "./CampaignAnalysisFlow.module.scss";
import CampaignQueryChat from "../CampaignQueryChat/CampaignQueryChat";
import CampaignContentQueryChat from "../CampaignContentQueryChat/CampaignContentQueryChat";
import CampaignConnectingDataSourcesQueryChat from "../CampaignConnectingDataSourcesQueryChat/CampaignConnectingDataSourcesQueryChat";

interface Props {
  showWhichOperation: "analysis" | "content" | "connecting-data-sources";
}

const CampaignAnalysisFlow: React.FC<Props> = (props) => {
  const { showWhichOperation } = props;
  return (
    <div className={styles["container"]}>
      {showWhichOperation === "analysis" && <CampaignQueryChat />}
      {showWhichOperation === "content" && <CampaignContentQueryChat />}
      {showWhichOperation === "connecting-data-sources" && (
        <CampaignConnectingDataSourcesQueryChat />
      )}  
    </div> 
  );
};

export default CampaignAnalysisFlow;
