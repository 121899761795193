import { Listbox } from "@headlessui/react";

import styles from "./Select.module.scss";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

interface SelectProps {
  options: {
    value: string | number | null;
    label: React.ReactNode;
  }[];
  value: string | number | null;
  onChange: (value: string | number | null) => void;
  width?: string;
  placeholderText?: string;
  isStatic?: boolean;
  optionClassName?: string;
  disabled?: boolean;
  className?: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const {
    onChange,
    options,
    value,
    width,
    placeholderText = "Select Option",
    isStatic,
    optionClassName,
    disabled = false,
    className
  } = props;

  const listboxRef = useRef(null);

  const [listOpen, setListOpen] = React.useState(false);

  useEffect(() => {
    const handleClick = (e: any) => {
      // @ts-ignore
      if (listboxRef?.current && !listboxRef?.current?.contains(e.target)) {
        setListOpen(false);
      }
    };

    if (isStatic) {
      document.addEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isStatic]);

  return (
    <div
      className={classNames(
        styles["custom-select"],
        disabled ? styles["disabled"] : "",
       className
      )}
      style={
        width
          ? {
              width: width,
            }
          : {}
      }
      ref={listboxRef}
    >
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={classNames(styles["select-button"])}
              onClick={() => setListOpen(!listOpen)}
            >
              {options.find((option) => option.value === value)?.label ||
                <span className={styles['placeholder-text']}>{placeholderText}</span>}
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <path
                  d="M5.29756 6L0.709648 0L9.88546 0L5.29756 6Z"
                  fill="#00718F"
                />
              </svg>
            </Listbox.Button>
            <Listbox.Options
              className={styles["option-list"]}
              static={isStatic ? listOpen : undefined}
            >
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  value={option.value}
                  className={classNames(styles["option"], optionClassName)}
                >
                  {option.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
