import React, { forwardRef } from "react";
import classNames from "classnames";

import { NinjaIcon } from "../../icons";
import styles from "./ChatCard.module.scss";
import TextElement from "../TextElement/TextElement";

interface ChatCardProps {
  showIcon?: boolean;
  message: string | React.ReactNode;
  messageClass?: string;
  className?: string;
}

const ChatCard = forwardRef<HTMLDivElement, ChatCardProps>((props, ref) => {
  const { showIcon = true, message, messageClass, className } = props;

  return (
    <div ref={ref} className={classNames(styles["container"], className)}>
      {showIcon && <NinjaIcon className={styles["icon"]} />}
      <div className={classNames(styles["message-container"], messageClass)}>
        <TextElement className={styles["message"]}>{message}</TextElement>
      </div>
    </div>
  );
});

ChatCard.displayName = "ChatCard";

export default ChatCard;
