import React from "react";

import styles from "./Recommended5GOfferingsCard.module.scss";
import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import WifiIcon from "../../icons/WifiIcon";
import IotIcon from "../../icons/IotIcon";
import SignalsIcon from "../../icons/SignalsIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const Recommended5GOfferingsCard: React.FC<Props> = (props) => {
  const { className, dummyLoaderDuration } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );


  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              recommendedOfferings: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.recommendation ? undefined : dummyLoaderDuration
      }
      type={props.cardType}
    >
      {" "}
      <TextElement className={styles["heading"]}>
        Recommended 5G Offerings
      </TextElement>
      <div className={styles["offering"]}>
        <WifiIcon />
        <TextElement>
          <span>High-Speed Internet Packages:</span> Offering speeds up to 2
          Gbps, tailored for businesses with demanding data requirements.
          Pricing starts from $99/month for basic packages, up to $299/month for
          premium packages with additional features such as priority support and
          enhanced security.
        </TextElement>
      </div>
      <div className={styles["offering"]}>
        <IotIcon />
        <TextElement>
          <span>5G-Powered IoT Solutions:</span> Customized IoT solutions
          leveraging 5G connectivity for enhanced efficiency and productivity.
          Packages starting from $199/month, with scalable options based on the
          number of connected devices and data usage.
        </TextElement>
      </div>
      <div className={styles["offering"]}>
        <SignalsIcon />
        <TextElement>
          <span>Enterprise Mobility Solutions:</span> 5G-enabled mobile plans
          with unlimited data, optimized for remote work and on-the-go
          productivity. Plans start from $79/month per user for basic packages,
          up to $149/month for premium plans with additional features such as
          international roaming and device insurance.
        </TextElement>
      </div>
    </Card>
  );
};

export default Recommended5GOfferingsCard;
