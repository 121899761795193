import React, { useState, useRef, useEffect } from "react";

import styles from "./NurtureStrategyQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EducationalContentDistribution from "./componenets/EducationalContentDistribution/EducationalContentDistribution";
import ProductDemoInvitations from "./componenets/ProductDemoInvitations/ProductDemoInvitations";
import CustomerTestimonialsDistribution from "./componenets/CustomerTestimonialsDistribution/CustomerTestimonialsDistribution";
import OverallExpectedResults from "./componenets/OverallExpectedResults/OverallExpectedResults";
import { updateCampaign } from "../../redux/campaignPinningSlice";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface NurtureStrategyQueryChatProps {
  className?: string;
}

const NurtureStrategyQueryChat: React.FC<NurtureStrategyQueryChatProps> = (
  props
) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const addOverallExpectedResults = (delay: number) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <OverallExpectedResults
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addCustomerTestimonialsDistribution = (delay: number) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <CustomerTestimonialsDistribution
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addProductDemoInvitations = (delay: number) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ProductDemoInvitations
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addEducationalContentDistribution = (delay: number) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <EducationalContentDistribution
            cardType="pinable"
            className={styles["card"]}
            dummyLoaderDuration={delay}
          />
        ),
      },
      ...pC,
    ]);
  };

  const handleCreateContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message="By leveraging insights from our known contacts, we've developed a series of targeted engagements tailored to your leads' behaviors and interactions. Let's dive into how this campaign will educate, engage, and ultimately convert your leads with personalized and relevant content."
          />
        ),
      },
      ...pC,
    ]);
    addEducationalContentDistribution(1000);
    
    setTimeout(() => {
      addProductDemoInvitations(2000);
    }, 1000);

    setTimeout(() => {
      addCustomerTestimonialsDistribution(3000);
    }, 2000);

    setTimeout(() => {
      addOverallExpectedResults(4000);
    }, 3000);

    setTimeout(() => {
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["card"], styles["continue-button"])}
              onClick={() => {
                selectedCampaign &&
                  dispatch(
                    updateCampaign({
                      ...selectedCampaign,
                      activeConnectingDataSourcesStep: true,
                    })
                  );

                navigate(
                  `${ROUTE_DEFINITIONS.MARKETING_CONNECTING_DATA_SOURCES.path}`
                );
              }}
            >
              <TextElement>Pin all and Continue 👍🏻</TextElement>
            </Button>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message="Happy with the nurture strategy I crafted? You can pin all and submit for approval."
            />
          ),
        },
        ...pC,
      ]);
    }, 4000);
  };

  const [conversation, setConversation] = useState<Question[]>([]);

  useEffect(() => {
    handleCreateContent();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {conversation?.map((item: Question, index: number) => (
            <div key={index}>{item.content}</div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default NurtureStrategyQueryChat;
