export enum SidebarItem {
  LOGO = "LOGO",
  AVATAR = "AVATAR",
  ADD_CAMPAIGN = "CREATE_NEW_CAMPAIGN",
  ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER",
  CAMPAIGN_PLANS_LIST = "CAMPAIGN_PLANS_LIST",
  MY_TEAM = "MY_TEAM",
  NOTIFICATIONS = "NOTIFICATIONS",
  BRAND_IDENTITY = "MY_BRAND",
  BRAND_STYLE_GUIDE = "BRAND_STYLE_GUIDE",
  BRAND_ASSETS = "BRAND_ASSETS",
  CAMPAIGN_CONTENT_BUILDER = "CAMPAIGN_CONTENT_BUILDER",
  CONNECTING_DATA_SOURCES = "CONNECTING_DATA_SOURCES",
  CAMPAIGN_ANALYSIS_BUILDER = "CAMPAIGN_ANALYSIS_BUILDER",
  INTEREST_STAGE_REPORT = "INTEREST_STAGE_REPORT",
  INTEREST_NURTURE_STRATEGY = "INTEREST_NURTURE_STRATEGY",
  INTEREST_STAGE_SETTINGS = "INTEREST_STAGE_SETTINGS",
  INTEREST_NURTURE_CONTENT = "INTEREST_NURTURE_CONTENT",
  SHORTLIST_STAGE_REPORT = "SHORTLIST_STAGE_REPORT",
  SHORTLIST_NURTURE_STRATEGY = "SHORTLIST_NURTURE_STRATEGY",
  SHORTLIST_STAGE_SETTINGS = "SHORTLIST_STAGE_SETTINGS",
  SHORTLIST_NURTURE_CONTENT = "SHORTLIST_NURTURE_CONTENT",
}
