import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";
import { isHttpResponseError } from "../lib/helpers";
import { number } from "prop-types";

export enum CampaignStatusType {
  APPROVED = "Approved",
  REJECTED = "Rejected",
  NEEDS_APPROVAL = "Need Approval",
  ACTIVE = "Active",
  DRAFT = "Draft",
}

export interface Campaign {
  id: number;
  title: string;
  status?: CampaignStatusType;
  createdBy?: string;
  updatedBy?: string;
  productName: string;
  campaignOverview?: string;
  campaignObjective?: string;
  campaignBudget?: string;
  campaignStartDate?: number;
  includedData: {
    pastInsights: number[];
    audienceSegmentation: number[];
    marketSentimentIds: number[];
    competitorIds: number[];
    competitorStrategies: boolean;
    recommendations: {
      [key: string]: boolean;
      targetAudienceAndEstimatedNumbers: boolean;
      recommendedOfferings: boolean;
      recommendedChannels: boolean;
      recommendedBudget: boolean;
      projectedRoi: boolean;
    };
    content: {
      [key: string]: boolean;
      googleAds: boolean;
      contentMarketing: boolean;
      emailMarketing: boolean;
      socialMediaAdvertising: boolean;
    };
  };
  activeContentStep?: boolean;
  activeAnalysisStep?: boolean;
  activeConnectingDataSourcesStep?: boolean;
  activeRecommendationAnalysisPhase?: boolean;
  updatedAt?: string;
}

export const initialCampaignData = {
  includedData: {
    pastInsights: [],
    audienceSegmentation: [],
    marketSentimentIds: [],
    competitorIds: [],
    competitorStrategies: true,
    recommendations: {
      targetAudienceAndEstimatedNumbers: true,
      recommendedOfferings: true,
      recommendedChannels: true,
      recommendedBudget: true,
      projectedRoi: true,
    },
    content: {
      googleAds: true,
      contentMarketing: true,
      emailMarketing: true,
      socialMediaAdvertising: true,
    },
  },
};

interface InitialStateType {
  campaigns: Campaign[];
  selectedCampaign: Campaign | null;
  loading: boolean;
  errorMessage: string;
}

interface FetchCampaignListResponse {
  success: boolean;
  pageSize: number;
  count: number;
  message: string;
  data: Campaign[];
}

export interface CreateCampaignResponse {
  success: boolean;
  message: string;
  data: Campaign;
}

interface UpdateCampaignResponse {
  success: boolean;
  message: string;
  data: Campaign;
}

interface DeleteCampaignResponse {
  success: boolean;
  message: string;
  campaignId?: number;
}

const initialState: InitialStateType = {
  campaigns: [],
  selectedCampaign: null,
  loading: false,
  errorMessage: "",
};

const httpService = new HttpService();

export const fetchCampaigns = createAsyncThunk<FetchCampaignListResponse>(
  "campaignPinning/fetchCampaigns",
  async () => {
    const response = await httpService.get<FetchCampaignListResponse>(
      "/api/campaigns/"
    );
    return response.data;
  }
);

export const createCampaign = createAsyncThunk<
  CreateCampaignResponse,
  Omit<Campaign, "id">
>("campaignPinning/createCampaign", async (campaign: Omit<Campaign, "id">) => {
  try {
    const response = await httpService.post<
      CreateCampaignResponse,
      Omit<Campaign, "id">
    >("/api/campaigns/", campaign);
    return response.data;
  } catch (error) {
    if (isHttpResponseError(error)) {
      return {
        success: false,
        message: error.data.message,
      } as CreateCampaignResponse;
    }
    return {
      success: false,
      message: "Failed to send OTP",
    } as CreateCampaignResponse;
  }
});

export const updateCampaign = createAsyncThunk<
  UpdateCampaignResponse,
  Campaign
>("campaignPinning/updateCampaign", async (campaign: Campaign) => {
  const { createdBy, updatedBy, updatedAt, ...rest } = campaign;
  const response = await httpService.update<UpdateCampaignResponse, Campaign>(
    `/api/campaigns/${campaign.id}/`,
    { ...rest, campaignStartDate: 1720099287 }
  );
  return response.data;
});

export const deleteCampaign = createAsyncThunk<DeleteCampaignResponse, number>(
  "campaignPinning/deleteCampaign",
  async (campaignId: number) => {
    try {
      const response = await httpService.remove<DeleteCampaignResponse>(
        `/api/campaigns/${campaignId}/`
      );
      return {
        ...response.data,
        campaignId,
      };
    } catch (error) {
      if (isHttpResponseError(error)) {
        return {
          success: false,
          message: error.data.message,
        } as DeleteCampaignResponse;
      }
      return {
        success: false,
        message: "Failed to send OTP",
      } as DeleteCampaignResponse;
    }
  }
);

const campaignPinningSlice = createSlice({
  name: "campaignPinning",
  initialState,
  reducers: {
    setSelectedCampaign: (state, action: PayloadAction<number>) => {
      if (!number) {
        state.selectedCampaign = null;
        return;
      }
      const foundCampaign = state.campaigns.find(
        (campaign) => campaign.id === action.payload
      );
      state.selectedCampaign = foundCampaign || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload.data;
      if (state?.selectedCampaign?.id) {
        const foundCampaign = action.payload.data.find(
          (campaign) => campaign.id === state.selectedCampaign?.id
        );
        state.selectedCampaign = foundCampaign || null;
      }
    });
    builder.addCase(fetchCampaigns.rejected, (state, action) => {});
    builder.addCase(fetchCampaigns.pending, (state, action) => {});

    // create campaign
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.campaigns.push(action.payload.data);
      state.selectedCampaign = action.payload.data;
      state.loading = false;
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.errorMessage = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(createCampaign.pending, (state, action) => {
      state.loading = true;
    });

    //update campaign
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      state.campaigns = state.campaigns.map((campaign) =>
        campaign.id === action.payload.data.id ? action.payload.data : campaign
      );
      state.selectedCampaign = {
        ...state.selectedCampaign,
        ...action.payload.data,
      };
      state.loading = false;
    });
    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.errorMessage = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(updateCampaign.pending, (state, action) => {
      state.loading = true;
    });

    // delete Campaign
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.campaigns = state.campaigns.filter(
        (campaign) => campaign.id !== action.payload.campaignId
      );
      state.selectedCampaign = null;
      state.loading = false;
    });
    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.errorMessage = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(deleteCampaign.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const {
  // createCampaign,
  setSelectedCampaign,
  // updateIncludedData,
  // updateSelectedCampaign,
} = campaignPinningSlice.actions;
export default campaignPinningSlice.reducer;
