import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";
import { ROUTE_DEFINITIONS } from "../pages/AppRoutes";
import { isHttpResponseError } from "../lib/helpers";

export enum UserRoleType {
  CMO = "CMO",
  MARKETING = "Marketer",
  PRIMARY_APPROVER = "Primary Approver",
  SECONDARY_APPROVER = "Secondary Approver",
}

interface SendOTPResponse {
  success: boolean;
  message: string;
  tempToken?: string;
}

export interface ForgotPasswordResponse {
  success: boolean;
  message: string;
}

export interface ResetPasswordResponse {
  success: boolean;
  message: string;
}

export interface User {
  userid: number;
  email: string;
  lastLogin: string;
  isActive: boolean;
  role: UserRoleType;
}

interface ValidateOTPResponse {
  success: boolean;
  message: string;
  data?: {
    refresh: string;
    access: string;
    userdata: User;
  };
}

interface AuthState {
  loggedInUser: User | null;
  authError: string | null;
  loading: boolean;
}


const initialState: AuthState = {
  loggedInUser: null,
  authError: null,
  loading: false,
};

const httpService = new HttpService();

export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ): Promise<SendOTPResponse> => {
    try {
      const response = await httpService.post<SendOTPResponse, any>(
        "/api/auth/send_otp/",
        {
          email: username,
          password,
        }
      );
      return response.data as SendOTPResponse;
    } catch (error) {
      if (isHttpResponseError(error)) {
        return {
          success: false,
          message: error.data.message,
        } as SendOTPResponse;
      }
      return {
        success: false,
        message: "Failed to send OTP",
      } as SendOTPResponse;
    }
  }
);

export const validateOtp = createAsyncThunk(
  "auth/validateOtp",
  async ({ otp }: { otp: string }, thunkAPI): Promise<ValidateOTPResponse> => {
    const temp_token = localStorage.getItem("tempToken");
    const response = await httpService.post<ValidateOTPResponse, any>(
      "/api/auth/validate_otp/",
      {
        otp,
        temp_token,
      }
    );
    return response.data as ValidateOTPResponse;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (
    { email }: { email: string },
    thunkAPI
  ): Promise<ForgotPasswordResponse> => {
    try {
      const response = await httpService.post<ForgotPasswordResponse, any>(
        "/api/auth/forgot-password/",
        {
          email,
          base_url: `${window.location.origin}/${ROUTE_DEFINITIONS.RESET_PASSWORD.path}`,
        }
      );
      return response.data as ForgotPasswordResponse;
    } catch (error) {
      if (isHttpResponseError(error)) {
        return {
          success: false,
          message: error.data.message,
        } as ForgotPasswordResponse;
      }
      return {
        success: false,
        message: "Failed to send OTP",
      } as ForgotPasswordResponse;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    { password, token }: { password: string; token: string },
    thunkAPI
  ): Promise<ResetPasswordResponse> => {
    try {
      const response = await httpService.post<ResetPasswordResponse, any>(
        `/api/auth/reset-password/?token=${token}`,
        {
          password,
          confirm_password: password,
        }
      );
      return response.data as ResetPasswordResponse;
    } catch (error) {
      if (isHttpResponseError(error)) {
        return {
          success: false,
          message: error.data.message,
        } as ResetPasswordResponse;
      }
      return {
        success: false,
        message: "Failed to send OTP",
      } as ResetPasswordResponse;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<User | null>) => {
      state.loggedInUser = action.payload;
    },
    login: (
      state,
      action: PayloadAction<{ username: string; password: string }>
    ) => {
      const { username, password } = action.payload;
      const response = httpService.post<SendOTPResponse, any>(
        "/api/auth/send_otp/",
        {
          username,
          password,
        }
      );
    },
    logout: (state) => {
      state.loggedInUser = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
      state.authError = null;
    });

    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false;
      const { success, tempToken } = action.payload;
      if (success) {
        localStorage.setItem("tempToken", tempToken || "");
      } else {
        state.authError = action.payload.message || "Failed to send OTP";
      }
    });

    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.authError = action.error.message || "Failed to send OTP";
    });

    builder.addCase(validateOtp.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(validateOtp.fulfilled, (state, action) => {
      state.loading = false;
      const { success, data } = action.payload;
      if (success) {
        state.loggedInUser = data?.userdata || null;
        localStorage.setItem("access_token", data?.access || "");
        localStorage.setItem("refresh_token", data?.refresh || "");
        localStorage.setItem("user", JSON.stringify(data?.userdata));
      }
    });

    builder.addCase(validateOtp.rejected, (state, action) => {
      state.loading = false;
      state.authError = action.error.message || "Failed to validate OTP";
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      const { success } = action.payload;
      if (!success) {
        state.authError = action.payload.message || "Failed to send OTP";
      }
    });

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.authError = action.error.message || "Failed to send OTP";
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      const { success } = action.payload;
      if (!success) {
        state.authError = action.payload.message || "Failed to reset password";
      }
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.authError = action.error.message || "Failed to reset password";
    });
   },
});

export const { login, logout, updateUserData } = authSlice.actions;

export default authSlice.reducer;
