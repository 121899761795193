import React from "react";

const SignalsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M79.9901 44.9742C79.9901 55.8503 79.9901 66.7273 79.9901 77.6051C79.9901 79.625 79.6151 79.995 77.5802 79.995C74.3303 79.995 71.0805 79.995 67.8306 79.995C66.2207 79.995 65.7083 79.4725 65.7083 77.8551C65.7083 55.8562 65.7083 33.8572 65.7083 11.8583C65.7083 10.2109 66.2457 9.67339 67.9056 9.67089C71.1872 9.67089 74.4687 9.67089 77.7502 9.67089C79.5001 9.67089 80.0001 10.1534 80.0001 11.8883C79.9967 22.9128 79.9934 33.9414 79.9901 44.9742Z"
        fill="#EB2701"
      />
      <path
        d="M43.7967 55.4762C43.7967 48.0715 43.7967 40.6669 43.7967 33.2622C43.7967 31.3473 44.2492 30.8973 46.1816 30.8973C49.4314 30.8973 52.6813 30.8973 55.9311 30.8973C57.611 30.8973 58.1285 31.3973 58.1285 33.0722C58.1285 47.9765 58.1285 62.8808 58.1285 77.7851C58.1285 79.5175 57.6285 79.995 55.8786 79.995H46.0441C44.2542 79.995 43.7942 79.525 43.7942 77.6926C43.7942 70.2879 43.795 62.8825 43.7967 55.4762Z"
        fill="#EB2701"
      />
      <path
        d="M21.9051 62.0233C21.9051 56.8369 21.9051 51.6488 21.9051 46.4591C21.9051 44.4592 22.3001 44.0667 24.335 44.0667C27.5848 44.0667 30.8346 44.0667 34.0845 44.0667C35.7019 44.0667 36.2244 44.5867 36.2244 46.1916C36.2244 56.7561 36.2244 67.3198 36.2244 77.8826C36.2244 79.45 35.6969 79.9875 34.162 79.99C30.7555 79.99 27.349 79.99 23.9425 79.99C22.4425 79.99 21.9101 79.47 21.9076 77.9601C21.9009 72.6553 21.9001 67.3431 21.9051 62.0233Z"
        fill="#EB2701"
      />
      <path
        d="M0.0136719 70.178C0.0136719 67.5856 0.0136719 65.0033 0.0136719 62.3984C0.0136719 60.961 0.553646 60.436 2.01358 60.4335C5.45008 60.4335 8.88658 60.4335 12.3231 60.4335C13.748 60.4335 14.308 60.9959 14.323 62.4334C14.323 67.6181 14.323 72.8045 14.323 77.9926C14.323 79.4351 13.778 79.9925 12.3581 79.9925C8.89158 80.0025 5.42341 80.0025 1.95358 79.9925C0.568645 79.9925 0.0411706 79.4526 0.0336709 78.0526C0.0136719 75.4278 0.0136719 72.8029 0.0136719 70.178Z"
        fill="#EB2701"
      />
      <path
        d="M20.7028 26.5301L24.9926 19.6104C25.1295 19.3651 25.2832 19.1296 25.4526 18.9055C25.6921 18.5906 26.0426 18.3789 26.4327 18.3134C26.8229 18.2479 27.2233 18.3336 27.5525 18.553C27.872 18.7562 28.1084 19.0668 28.2193 19.4288C28.3302 19.7908 28.3083 20.1806 28.1575 20.5279C28.054 20.7551 27.9319 20.9733 27.7925 21.1803C25.3626 25.1077 22.9202 29.0275 20.5128 32.9648C20.0128 33.7672 19.4104 34.2722 18.4454 34.0047C17.4805 33.7372 17.303 32.9873 17.303 32.0948C17.313 22.0703 17.313 12.0458 17.303 2.02127C17.303 0.716329 17.853 0.0213623 18.8804 0.0213623C19.9079 0.0213623 20.4978 0.731328 20.5128 2.08376C20.5128 9.89339 20.5128 17.7013 20.5128 25.5076V26.5076L20.7028 26.5301Z"
        fill="#EB2701"
      />
      <path
        d="M7.60082 7.62598C7.41582 7.90096 7.22333 8.17095 7.04834 8.45094C5.73091 10.5733 4.42347 12.7007 3.09603 14.8181C2.48356 15.7981 1.6361 16.0681 0.811142 15.5681C-0.0138184 15.0681 -0.221308 14.1257 0.388662 13.1357C2.56356 9.60088 4.74762 6.07022 6.94085 2.54372C7.30333 1.95875 7.65831 1.37128 8.02579 0.793806C8.2011 0.472936 8.48585 0.225734 8.82817 0.0972507C9.17049 -0.0312325 9.54757 -0.0324432 9.89071 0.0938394C10.6407 0.318829 10.9857 0.881302 10.9907 1.65626C10.9907 1.93875 10.9907 2.21874 10.9907 2.50122V31.8398C10.9907 33.3622 10.4507 34.0897 9.36573 34.0772C8.28078 34.0647 7.78831 33.3272 7.78831 31.8648C7.78831 24.1469 7.78831 16.4297 7.78831 8.71343V7.69097L7.60082 7.62598Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default SignalsIcon;
