import React from "react";

const IotIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="89"
      height="83"
      viewBox="0 0 89 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M88.6842 31.293C88.626 31.3905 88.5772 31.4933 88.5387 31.6001C87.7604 34.7572 85.5364 36.5632 82.3031 36.681C82.0721 36.681 81.8412 36.7018 81.4832 36.7203V37.7411C81.4832 42.6164 81.4971 47.4917 81.4832 52.367C81.4832 55.6003 79.9174 57.9259 77.2315 58.8197C72.8088 60.2816 68.6517 57.2793 68.6148 52.5448C68.5778 47.6141 68.6148 42.681 68.6148 37.748V36.7641C67.8919 36.6833 67.2037 36.6625 66.5363 36.5332C63.2129 35.8404 61.2037 33.0967 61.4554 29.6348C61.6863 26.4662 64.1344 23.965 67.4185 23.8911C72.5201 23.7779 77.6287 23.7987 82.7327 23.8911C85.5387 23.9373 87.7604 25.8749 88.4786 28.5816C88.5364 28.8126 88.6149 29.0158 88.6842 29.2306V31.293Z"
        fill="#EB2701"
      />
      <path
        d="M0 29.3877C0.0643548 29.23 0.119869 29.0689 0.166283 28.905C0.903008 25.6556 3.64667 23.614 6.94461 23.868C10.2426 24.1221 12.7714 26.6117 12.813 30.002C12.8985 36.5794 12.8569 43.166 12.8477 49.7342C12.8477 51.173 12.9216 52.6396 12.6744 54.0437C12.1225 57.1823 9.59128 59.1731 6.42498 59.1708C3.29794 59.1708 0.84758 57.1915 0.166283 54.1199C0.119509 53.9562 0.0640016 53.7951 0 53.6372L0 29.3877Z"
        fill="#EB2701"
      />
      <path
        d="M38.7693 60.8059C28.1457 60.7758 19.4367 52.1014 19.5013 41.6164C19.5706 30.5955 27.8709 22.3045 38.8202 22.3183C49.7694 22.3322 58.0905 30.6902 58.0789 41.6672C58.0697 52.21 49.3653 60.8382 38.7693 60.8059ZM32.3767 41.4732C32.3929 45.1383 35.1481 47.9028 38.7763 47.9097C42.4507 47.9097 45.222 45.1245 45.2151 41.4224C45.1773 39.7406 44.4809 38.1409 43.2758 36.9673C42.0707 35.7937 40.4531 35.1399 38.771 35.1465C37.0888 35.1532 35.4765 35.8198 34.2807 37.0029C33.0849 38.186 32.4012 39.7912 32.3767 41.4732Z"
        fill="#EB2701"
      />
      <path
        d="M37.0879 83C27.5059 82.7875 17.6097 79.1709 9.54963 71.0623C8.79905 70.3071 8.46186 69.4318 8.74593 68.3879C8.85868 67.9211 9.10547 67.4976 9.4559 67.1693C9.80633 66.841 10.2451 66.6224 10.7182 66.5403C11.8245 66.2978 12.6513 66.8036 13.4065 67.5634C17.57 71.8131 22.8033 74.8602 28.5544 76.3833C39.8962 79.3857 50.5106 77.7921 60.1781 70.9791C61.7255 69.8891 63.0719 68.5103 64.4968 67.2493C65.4206 66.4318 66.4899 66.2493 67.5292 66.7874C68.4876 67.2724 69.125 68.3048 68.8941 69.3509C68.7431 70.0174 68.4179 70.6319 67.9518 71.1316C62.7924 76.4434 56.5453 79.8614 49.4067 81.7021C45.9263 82.582 42.3812 82.9954 37.0879 83Z"
        fill="#EB2701"
      />
      <path
        d="M8.46204 13.7848C8.83848 13.1659 9.11331 12.4499 9.61678 11.9372C14.4582 6.88776 20.6194 3.29824 27.3998 1.57687C40.3329 -1.75802 52.3976 0.124206 63.3723 7.9164C65.0305 9.09655 66.5132 10.54 68.0051 11.9418C69.2338 13.0966 69.2592 14.7132 68.1945 15.7848C67.1298 16.8564 65.5501 16.801 64.3446 15.6116C56.2937 7.66005 46.5061 4.46834 35.3814 5.38059C26.8109 6.07343 19.3628 9.46837 13.2473 15.6162C12.3027 16.5654 11.2496 16.7894 10.1803 16.3091C9.21955 15.8887 8.63525 14.9603 8.46204 13.7848Z"
        fill="#EB2701"
      />
      <path
        d="M38.64 71.9075C31.7831 71.8175 25.4505 69.8036 19.9331 65.404C18.5659 64.3117 18.2888 62.7597 19.2403 61.5449C20.1918 60.3301 21.7623 60.2423 23.1664 61.3301C27.627 64.832 33.1346 66.734 38.8056 66.7311C44.4765 66.7282 49.9822 64.8204 54.4391 61.3139C55.8248 60.2377 57.4091 60.337 58.3514 61.5634C59.2937 62.7897 59.0235 64.3001 57.6955 65.3671C52.1712 69.8013 45.8155 71.829 38.64 71.9075Z"
        fill="#EB2701"
      />
      <path
        d="M39.6006 11.1266C46.2013 11.1968 52.5832 13.5024 57.7047 17.667C59.0257 18.7271 59.2774 20.2698 58.3444 21.4731C57.4114 22.6763 55.804 22.8218 54.5014 21.7525C45.7046 14.5215 31.9378 14.4938 23.0925 21.7525C21.7853 22.8241 20.1848 22.6763 19.2518 21.4708C18.3188 20.2652 18.5728 18.7109 19.88 17.667C25.1872 13.3991 31.7484 11.1289 39.6006 11.1266Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default IotIcon;
