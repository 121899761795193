import React from "react";

const WifiIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="88"
      height="63"
      viewBox="0 0 88 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 19L8 27C27.88 7.11998 60.12 7.11998 80 27L88 19C63.72 -5.28002 24.32 -5.28002 0 19ZM32 51L44 63L56 51C49.4 44.36 38.64 44.36 32 51ZM16 35L24 43C35.04 31.96 52.96 31.96 64 43L72 35C56.56 19.56 31.48 19.56 16 35Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default WifiIcon;
