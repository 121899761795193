import React from "react";

import styles from "./SocialMediaContentCard.module.scss";

import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface SocialMediaContentCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const SocialMediaContentCard: React.FC<SocialMediaContentCardProps> = (
  props
) => {
  const { className, dummyLoaderDuration, cardType } = props;

  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const handlePinned = (isPinned: boolean) => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            content: {
              ...selectedCampaign?.includedData?.content,
              socialMediaAdvertising: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={dummyLoaderDuration}
    >
      <TextElement className={styles["heading"]}>
        Social Media Advertising (FB Ads)
      </TextElement>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Ad A Headline
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Upgrade to 5G and Stay Ahead of the Competition!
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Description
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Unlock the potential of 5G for your business. Upgrade now and enjoy
            faster speeds, lower latency, and enhanced productivity.
            Limited-time offer - act now!
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>Image</TextElement>
        <div className={styles["content"]}>
          <img src="contentImages/image1.png" />
        </div>
        <TextElement className={styles["content-heading"]}>
          Ad B Headline
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Experience the Power of 5G Technology Today!
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Description
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Transform your business with 5G technology. Upgrade now and
            experience lightning-fast connectivity and seamless communication.
            Click to upgrade and stay ahead of the competition!
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>Image</TextElement>
        <div className={styles["content"]}>
          <img src="contentImages/image2.png" />
        </div>
      </div>
    </Card>
  );
};

export default SocialMediaContentCard;
