import React from "react";

const NotificationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <g opacity="0.8">
        <path
          d="M17.85 13.2861V13.18V10.0002C17.8486 8.6187 17.3584 7.28231 16.4661 6.22766C15.5738 5.17301 14.3371 4.46814 12.975 4.2379L12.85 4.21677V4.09V3C12.85 2.77457 12.7604 2.55837 12.601 2.39896C12.4416 2.23955 12.2254 2.15 12 2.15C11.7746 2.15 11.5584 2.23955 11.399 2.39896C11.2396 2.55837 11.15 2.77457 11.15 3V4.09V4.21677L11.025 4.2379C9.66287 4.46814 8.42616 5.17301 7.5339 6.22766C6.64167 7.28227 6.15144 8.6186 6.15 10M17.85 13.2861L6.15 10M17.85 13.2861L17.95 13.3214C18.5044 13.5174 18.9846 13.8801 19.3247 14.3597C19.6649 14.8393 19.8483 15.4124 19.85 16.0004V18C19.85 18.2254 19.7604 18.4416 19.601 18.601C19.4416 18.7604 19.2254 18.85 19 18.85H15.86H15.7453L15.7153 18.9607C15.4936 19.7763 15.0097 20.4963 14.3383 21.0096C13.6669 21.5229 12.8452 21.801 12 21.801C11.1548 21.801 10.3331 21.5229 9.6617 21.0096C8.99026 20.4963 8.5064 19.7763 8.28475 18.9607L8.25468 18.85H8.14H5C4.77457 18.85 4.55837 18.7604 4.39896 18.601C4.23955 18.4416 4.15 18.2254 4.15 18V16.0002C4.1517 15.4123 4.33517 14.8393 4.67529 14.3597C5.01545 13.8801 5.49563 13.5174 6.05 13.3214L6.15 13.2861V13.18L6.15 10.0002M17.85 13.2861L6.15 10.0002M6.15 10C6.15 10.0001 6.15 10.0001 6.15 10.0002M6.15 10L6.15 10.0002M16 13.15H16.15V13V10C16.15 8.89935 15.7128 7.84378 14.9345 7.06551C14.1562 6.28723 13.1006 5.85 12 5.85C10.8994 5.85 9.84378 6.28723 9.06551 7.06551C8.28723 7.84378 7.85 8.89935 7.85 10V13V13.15H8H16ZM10.28 18.85H10.0202L10.1501 19.075C10.3377 19.3999 10.6071 19.6701 10.9314 19.8587C11.2558 20.0473 11.6239 20.1477 11.9991 20.15L12.0009 20.15C12.3761 20.1477 12.7442 20.0473 13.0686 19.8587C13.3929 19.6701 13.6623 19.3999 13.8499 19.075L13.9798 18.85H13.72H10.28ZM18 17.15H18.15V17V16C18.15 15.695 18.0288 15.4025 17.8132 15.1868C17.5975 14.9712 17.305 14.85 17 14.85H7C6.695 14.85 6.40249 14.9712 6.18683 15.1868C5.97116 15.4025 5.85 15.695 5.85 16V17V17.15H6H18Z"
          fill="#EB2701"
          stroke="white"
          strokeWidth="0.3"
        />
        <circle cx="19" cy="16" r="4.5" fill="#F72020" stroke="white" />
      </g>
    </svg>
  );
};

export default NotificationIcon;
