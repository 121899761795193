import React from "react";

import styles from "../CampaignContentQueryChat.module.scss";
import classNames from "classnames";
import Button from "../../Button/Button";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import TextElement from "../../TextElement/TextElement";
import { updateCampaign } from "../../../redux/campaignPinningSlice";
import { ROUTE_DEFINITIONS } from "../../../pages/AppRoutes";

interface PinAllAndContinueButtonProps {}

const PinAllAndContinueButton: React.FC<
  PinAllAndContinueButtonProps
> = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  return (
    <Button
      className={classNames(styles["card"], styles["continue-button"])}
      onClick={() => {
        selectedCampaign &&
          dispatch(
            updateCampaign({
              ...selectedCampaign,
              activeConnectingDataSourcesStep: true,
              includedData: {
                ...selectedCampaign.includedData,
                content: {
                  googleAds: true,
                  contentMarketing: true,
                  emailMarketing: true,
                  socialMediaAdvertising: true,
                },
              },
            })
          );
        navigate(`${ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path}`);
      }}
    >
      <TextElement>Pin all and submit for approval 👍🏻</TextElement>
    </Button>
  );
};

export default PinAllAndContinueButton;
