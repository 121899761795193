import React from "react";

import styles from "./GoogleAdsCard.module.scss";

import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface GoogleAdsCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const GoogleAdsCard: React.FC<GoogleAdsCardProps> = (props) => {
  const { className, cardType, dummyLoaderDuration } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const handlePinned = (isPinned: boolean) => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            content: {
              ...selectedCampaign?.includedData?.content,
              googleAds: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={dummyLoaderDuration}
      type={cardType}
    >
      <TextElement className={styles["heading"]}>
        Google Ads (Search Ads)
      </TextElement>
      <table>
        <tbody>
          <tr>
            <td>Ad A Title</td>
            <td>Experience Lightning-Fast Connectivity with 5G!</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              Unlock the power of 5G for your business. Boost productivity and
              efficiency with speeds up to 10 times faster than 4G. Learn more!
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>Ad A Title</td>
            <td>Revolutionize Your Business with 5G Technology!</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              Upgrade to 5G technology and propel your business forward.
              Experience lightning-fast speeds and seamless connectivity. Click
              to learn more!
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default GoogleAdsCard;
