import React from "react";

import styles from "./AverageChannelPerformance.module.scss";
import ListingCard from "../ListingCard/ListingCard";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CardType } from "../Card/Card";

interface Props {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const AverageChannelPerformance: React.FC<Props> = (props) => {
  const { className, dummyLoaderDuration } = props;

  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    // if (selectedCampaign && selectedCampaign?.includedData) {
    //   dispatch(
    //     updateCampaign({
    //       ...selectedCampaign,
    //       includedData: {
    //         ...selectedCampaign.includedData,
    //         pastInsights: {
    //           ...selectedCampaign.includedData.pastInsights,
    //           averageChannelPerformance: isPinned,
    //         },
    //       },
    //     })
    //   );
    // }
  };

  return (
    <ListingCard
      className={classNames(styles["card"], className)}
      listHeading="Average Channel Performance"
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.pastCampaignAnalysis
          ? undefined
          : dummyLoaderDuration
      }
      cardType={props.cardType}
      list={[
        {
          heading: "Email marketing",
          text: "Average CPA of $8 and a conversion rate of 12%",
        },
        {
          heading: "Paid search",
          text: "Average CPA of $10 and a conversion rate of 9%.",
        },
        {
          heading: "Social media advertising",
          text: "Average CPA of $12 and a conversion rate of 7%",
        },
      ]}
    />
  );
};

export default AverageChannelPerformance;
