import React, { useEffect, useState } from "react";

import styles from "./Tabs.module.scss";
import TextElement from "../TextElement/TextElement";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams for accessing URL parameters
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import classNames from "classnames";

interface TabsProps {
  tabs: { id: number; label: string; content: React.ReactNode }[];
  defaultIndex?: number;
  className?: string;
  basePath: string;
  tabContentClassName?: string;
  onTabContentClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultIndex = 1,
  className,
  basePath,
  tabContentClassName,
  onTabContentClick,
}) => {
  const tab = parseInt(
    new URLSearchParams(window.location.search).get("tab") || "1"
  );

  const [campaignActiveTab, setCampaingActiveTab] = useState(tab);

  const navigate = useNavigate();

  useEffect(() => {
    setCampaingActiveTab(
      parseInt(new URLSearchParams(window.location.search).get("tab") || "1")
    );
  }, [window.location.search]);

  const handleTabClick = (index: number) => {
    navigate(`${basePath}?tab=${index}`);
  };

  return (
    <div className={classNames(styles["tab__group"], className)}>
      <div className={styles["tab__list"]}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={
              tab.id === campaignActiveTab
                ? styles["item--active"]
                : styles["item"]
            }
            onClick={() => handleTabClick(tab.id)}
          >
            <span className={styles["counter"]}>{index + 1}</span>
            <TextElement className={styles["label"]}>{tab.label}</TextElement>
          </button>
        ))}
      </div>
      <div className={styles["tab__panels"]}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={classNames(styles["panel"], tabContentClassName)}
            style={{ display: tab.id === campaignActiveTab ? "block" : "none" }}
            onClick={(e) => {
              if (onTabContentClick) onTabContentClick(e);
            }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
