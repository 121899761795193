import React from "react";

const PricingTagIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M17.0298 36.5298L0 19.5V22.979C0 23.901 0.365297 24.7881 1.02631 25.4317L14.5771 38.9825C15.9339 40.3393 18.1431 40.3393 19.4999 38.9825L30.3022 28.1801C31.6591 26.8233 31.6591 24.6142 30.3022 23.2573L17.0298 36.5298Z"
        fill="#EB2701"
      />
      <path
        d="M14.5771 30.2848C15.2555 30.9633 16.1426 31.3112 17.0298 31.3112C17.9169 31.3112 18.8041 30.9633 19.4825 30.2848L30.2848 19.4825C31.6417 18.1257 31.6417 15.9165 30.2848 14.5597L16.7341 1.00891C16.0905 0.365297 15.2033 0 14.2814 0H3.47902C1.56556 0 0 1.56556 0 3.47902V14.2814C0 15.2033 0.365297 16.0905 1.02631 16.7341L14.5771 30.2848ZM3.47902 3.47902H14.2814L27.8321 17.0298L17.0298 27.8321L3.47902 14.2814V3.47902Z"
        fill="#EB2701"
      />
      <path
        d="M7.39314 9.56752C8.59402 9.56752 9.56752 8.59402 9.56752 7.39314C9.56752 6.19226 8.59402 5.21875 7.39314 5.21875C6.19226 5.21875 5.21875 6.19226 5.21875 7.39314C5.21875 8.59402 6.19226 9.56752 7.39314 9.56752Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default PricingTagIcon;
