import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

interface GenerateSegmentationInput {
  campaignPlanId: number;
}

export interface GenerateSegmentationResponse {
  segments: {
    [key: string]: string;
  }[];
}

interface FetchPastCampaignAnalysisInput {
  campaignPlanId: number;
}

export interface FetchPastCampaignAnalysisResponse {
  analysis: {
    metric: string;
    value: string;
    definition: string;
    industry: string;
    geography: string;
  }[];
}

interface FetchBudgetAllocationForChannelInput {
  campaignPlanId: number;
}

interface BudgetAllocationForChannel {
  title: string;
  description: string;
  "budget allocation": {
    channel: string;
    spend: number;
    "projected ROI": number;
  }[];
}

interface FetchBudgetAllocationForChannelResponse {
  recommendations: {
    "Marketing channels": BudgetAllocationForChannel[];
    "Anticipated ROI": number;
    "Total marketing budget": number;
    "Expected return": number;
  };
}

interface StateType {
  audienceSegments: {
    [key: string]: string;
  }[];
  pastCampaignAnalysis: {
    [key: string]: string;
  }[];
  recommendation: {
    budgetAllocations: BudgetAllocationForChannel[];
    anticipatedROI: number;
    totalMarketingBudget: number;
    expectedReturn: number;
  };
  loading?: boolean;
  error?: string | null;
  loadedStateForAnalysis: {
    audienceSegments: boolean;
    pastCampaignAnalysis: boolean;
    recommendation: boolean;
    competitors: boolean;
    marketSentiments: boolean;
  };
}

const initialState: StateType = {
  audienceSegments: [],
  loading: false,
  error: null,
  pastCampaignAnalysis: [],
  recommendation: {
    budgetAllocations: [],
    anticipatedROI: 0,
    totalMarketingBudget: 0,
    expectedReturn: 0,
  },
  loadedStateForAnalysis: {
    audienceSegments: false,
    pastCampaignAnalysis: false,
    recommendation: false,
    competitors: false,
    marketSentiments: false,
  },
};

export const fetchBudgetAllocationForDifferentChannels = createAsyncThunk<
  FetchBudgetAllocationForChannelResponse,
  FetchBudgetAllocationForChannelInput
>(
  "campaignAnalysisQueryChat/fetchBudgetAllocationForDifferentChannels",
  async (input) => {
    try {
      const httpService = new HttpService(
        process.env.REACT_APP_RECOMMENDATION_API_URL
      );
      const response = await httpService.post<
        FetchBudgetAllocationForChannelResponse,
        FetchBudgetAllocationForChannelInput
      >("/budget_recommendations/", input);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPastCampaignAnalysis = createAsyncThunk<
  FetchPastCampaignAnalysisResponse,
  FetchPastCampaignAnalysisInput
>("campaignAnalysisQueryChat/fetchPastCampaignAnalysis", async (input) => {
  try {
    const httpService = new HttpService(
      process.env.REACT_APP_PAST_CAMPAIGN_ANALYSIS_API_URL
    );
    const response = await httpService.post<
      FetchPastCampaignAnalysisResponse,
      FetchPastCampaignAnalysisInput
    >("/past_campaign_analysis", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchSegments = createAsyncThunk<
  GenerateSegmentationResponse,
  GenerateSegmentationInput
>("campaignAnalysisQueryChat/fetchSegments", async (input) => {
  try {
    const httpService = new HttpService(
      process.env.REACT_APP_SEGMNETATION_API_URL
    );
    const response = await httpService.post<
      GenerateSegmentationResponse,
      GenerateSegmentationInput
    >("/generate_segments/", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const campaignAnalysisQueryChatSlice = createSlice({
  name: "campaignAnalysisQueryChat",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLoadedStateForAnalysis: (
      state,
      action: PayloadAction<{
        audienceSegments: boolean;
        pastCampaignAnalysis: boolean;
        recommendation: boolean;
        competitors: boolean;
        marketSentiments: boolean;
      }>
    ) => {
      state.loadedStateForAnalysis = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Segments
    builder
      .addCase(fetchSegments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.loading = false;
        state.audienceSegments = action.payload.segments;
      })
      .addCase(fetchSegments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching audience segments");
      });
    // fetch past campaign analaysis
    builder
      .addCase(fetchPastCampaignAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPastCampaignAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.pastCampaignAnalysis = action.payload.analysis;
      })
      .addCase(fetchPastCampaignAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching past campaign analysis");
      });
    // fetch budget allocation for different channels
    builder
      .addCase(fetchBudgetAllocationForDifferentChannels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBudgetAllocationForDifferentChannels.fulfilled,
        (state, action) => {
          state.loading = false;
          const {
            "Marketing channels": budgetAllocations,
            "Anticipated ROI": anticipatedROI,
            "Total marketing budget": totalMarketingBudget,
            "Expected return": expectedReturn,
          } = action.payload.recommendations;
          state.recommendation = {
            budgetAllocations,
            anticipatedROI,
            totalMarketingBudget,
            expectedReturn,
          };
        }
      )
      .addCase(
        fetchBudgetAllocationForDifferentChannels.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          throw new Error("Error fetching budget allocation");
        }
      );
  },
});

export const { setLoading, updateLoadedStateForAnalysis } =
  campaignAnalysisQueryChatSlice.actions;

export default campaignAnalysisQueryChatSlice.reducer;
