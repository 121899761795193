import React, { useEffect, useState } from "react";

import styles from "./MarketSentimentCard.module.scss";
import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useCampaignQueryChat from "../CampaignQueryChat/useCampaignQueryChat";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface MarketSentiment {
  id: number;
  imageUrl: string;
  heading: string;
  description: string;
}

interface MarketSentimentCardProps {
  className?: string;
  id?: number;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const MarketSentimentCard: React.FC<MarketSentimentCardProps> = ({
  className,
  id,
  dummyLoaderDuration,
  cardType,
}) => { 
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { marketSentiments } = useCampaignQueryChat();
  const { loadedStateForAnalysis } = useAppSelector((state) => state.campaignPlanAnalysisQueryChat);


  const [marketSentiment, setMarketSentiment] =
    useState<MarketSentiment | null>(null);

  useEffect(() => {
    if (marketSentiments && id) {
      const sentiment = marketSentiments.find(
        (sentiment) => sentiment.id === id
      );
      if (sentiment) setMarketSentiment(sentiment);
    }
  }, [id, selectedCampaign?.includedData?.marketSentimentIds]);

  const handlePin = (isPinned: boolean) => {
    if (!selectedCampaign?.includedData) return;
    let updatedMarketSentimentIds =
      [...selectedCampaign?.includedData?.marketSentimentIds] || [];
    if (isPinned) {
      updatedMarketSentimentIds.push(id as number);
    } else if (id && updatedMarketSentimentIds.includes(id as number)) {
      updatedMarketSentimentIds = updatedMarketSentimentIds.filter(
        (marketSentimentId) => marketSentimentId !== id
      );
    }
    dispatch(
      updateCampaign({
        ...selectedCampaign,
        includedData: {
          ...selectedCampaign.includedData,
          marketSentimentIds: Array.from(new Set(updatedMarketSentimentIds)),
        },
      })
    );
  };

  return (
    <Card
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.marketSentiments ? undefined : dummyLoaderDuration
      }
      type={cardType}
    >
      <TextElement className={styles["heading"]}>
        {marketSentiment?.heading}
      </TextElement>
      <TextElement className={styles["description"]}>
        {marketSentiment?.description}
      </TextElement>
      <img
        className={styles["media"]}
        src={marketSentiment?.imageUrl}
        alt="Market Sentiment"
      />
    </Card>
  );
};

export default MarketSentimentCard;
