import React, { useEffect, useState } from "react";

import styles from "./ApproverCampaignsList.module.scss";
import { TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TableComponent from "../../componenets/Table/Table";
import SearchInput from "../../componenets/SearchInput/SearchInput";
import BinIcon from "../../icons/BinIcon";
import {
  Campaign,
  CampaignStatusType,
  deleteCampaign,
  fetchCampaigns,
  setSelectedCampaign,
} from "../../redux/campaignPinningSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Tag from "../../componenets/Tag/Tag";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import DeleteCampaignModal from "../../componenets/DeleteCampaignModal/DeleteCampaignModal";
import NinjaResponseModal from "../../componenets/NinjaResponseModal/NinjaResponseModal";

interface Props {}

const ApproverCampaignsList: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { campaigns } = useAppSelector((state) => state.campaignData);
  const navigate = useNavigate();

  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState<boolean>(false);
  const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] =
    useState<boolean>(false);
  const [deleteCampaignName, setDeleteCampaignName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[] | []>(
    campaigns 
  );

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredCampaigns(campaigns);
    } else {
      setFilteredCampaigns(
        campaigns.filter((campaign) =>
          campaign.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, campaigns]);

  const handleDeleteCampaigns = async () => {
    if (selectedCampaignIds.length === 0) {
      return;
    }
    try {
      setIsDeleteConfirmModalOpen(false);
      Promise.all(
        selectedCampaignIds.map((campaignId) =>
          dispatch(deleteCampaign(campaignId)).unwrap()
        )
      ).then(() => {
        setIsDeleteSuccessModalOpen(true);
        setTimeout(() => {
          setIsDeleteSuccessModalOpen(false);
          setSelectedCampaignIds([]);
        }, 2000);
      });
    } catch (error) {
      console.error("Error deleting campaigns", error);
    }
  };

  const getTagBackgroundColor = (text: string) => {
    switch (text as CampaignStatusType) {
      case CampaignStatusType.APPROVED:
        return "#EB2701";
      case CampaignStatusType.ACTIVE:
        return "#38B05A";
      case CampaignStatusType.REJECTED:
        return "#EB8D01";
      default:
        return "#02285E";
    }
  };

  return (
    <ApproverViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          My Campaigns
        </TextElement>
      }
    >
      <NinjaResponseModal
        isOpen={isDeleteSuccessModalOpen}
        closeModal={() => {}}
        heading={
          selectedCampaignIds.length === 1
            ? deleteCampaignName
            : `Selected Campaigns`
        }
        message="successfully deleted!"
      />

      <DeleteCampaignModal
        isOpen={isDeleteConfirmModalOpen}
        closeModal={() => setIsDeleteConfirmModalOpen(false)}
        message={
          selectedCampaignIds.length === 1
            ? `Are you sure you want to delete ${deleteCampaignName} campaign?`
            : `Are you sure you want to delete selected campaigns?`
        }
        onOk={handleDeleteCampaigns}
      />

      <div className={styles["filter-container"]}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className={styles["delete-btn"]}
          onClick={() => {
            if (selectedCampaignIds.length === 0) return;
            setDeleteCampaignName(
              (prevName) =>
                filteredCampaigns.find(
                  (campaign) => campaign.id === selectedCampaignIds[0]
                )?.title as string
            );
            setIsDeleteConfirmModalOpen(true);
          }}
        >
          <BinIcon />
          <span>Delete</span>
        </button>
      </div>
      <div className={styles["table-wrapper"]}>
        <TableComponent
          rows={
            filteredCampaigns.map((campaign) => ({
              id: campaign?.id,
              name: (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setSelectedCampaign(campaign?.id as number));
                    navigate(ROUTE_DEFINITIONS.APPROVER_CAMPAGIN_DATA.path);
                  }}
                >
                  {campaign?.title}
                </a>
              ),
              createdBy: campaign?.createdBy,
              lastDateModified: new Date(
                campaign?.updatedAt as string
              ).toDateString(),
              status: (
                <Tag
                  text={campaign?.status?.toString() as string}
                  backgroundColor={getTagBackgroundColor(
                    campaign?.status as CampaignStatusType
                  )}
                />
              ),
            })) || []
          }
          columns={[
            {
              name: "Campaign",
              key: "name",
            },
            {
              name: "Created By",
              key: "createdBy",
            },
            {
              name: "Last Date Modified",
              key: "lastDateModified",
            },
            {
              name: "Status",
              key: "status",
            },
          ]}
          rowClassName={styles["table-row"]}
          onRowSelect={(row) => {
            setSelectedCampaignIds([...selectedCampaignIds, row.id as number]);
          }}
          onRowSelectAll={(rows) => {
            setSelectedCampaignIds(rows.map((row) => row.id as number));
          }}
        />
      </div>
    </ApproverViewLayout>
  );
};

export default ApproverCampaignsList;
