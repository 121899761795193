import React, { useEffect } from "react";

import styles from "./SideBar.module.scss";
import NotificationIcon from "../../icons/NotificationIcon";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchNotifications } from "../../redux/notificationsSlice";

interface Props {
  renderType?: "icon" | "icon-with-text";
}

const NotificationsSideIcon: React.FC<Props> = ({
  renderType = "icon-with-text",
}) => {
  const dispatch = useAppDispatch();

  const { notifications, unreadCount } = useAppSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (!token) {
      console.error("No access token found!");
      return;
    }

    // Construct the WebSocket URL with the token as a query parameter
    const socketUrl = `wss://dev-api.infotechconsultancy.io/ws/notifications/?token=${token}`;
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    socket.onmessage = (event) => {
      console.log("New notification received", event.data);
      // Here you would handle the incoming notification data
      dispatch(fetchNotifications());
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event.reason);
    };

    return () => {
      socket.close(); // Clean up the connection when the component unmounts
    };
  }, []);

  if (renderType === "icon") {
    return <NotificationIcon className={styles["icon"]} />;
  }

  return (
    <>
      <NotificationIcon className={styles["icon"]} />
      <TextElement>Notifications</TextElement>
    </>
  );
};

export default NotificationsSideIcon;
