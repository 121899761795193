import React from "react";

import styles from "./RecommendedMarketingChannels.module.scss";
import ListingCard from "../ListingCard/ListingCard";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CardType } from "../Card/Card";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const RecommendedMarketingChannels: React.FC<Props> = (props) => {
  const { className, dummyLoaderDuration, cardType } = props;

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { recommendation } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              recommendedChannels: isPinned,
            },
          },
        })
      );
  };

  return (
    <ListingCard
      className={classNames(styles["container"], className)}
      listHeading={"Recommended Marketing Channels"}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.recommendation ? undefined : dummyLoaderDuration
      }
      cardType={cardType}
      list={
        recommendation?.budgetAllocations?.map((budgetAllocation) => {
          return {
            heading: budgetAllocation.title,
            text: budgetAllocation.description,
          };
        }) || []
      }
    />
  );
};

export default RecommendedMarketingChannels;
