import React, { useState, useRef, useEffect, useCallback } from "react";

import styles from "./CampaignQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";
import MarketingBriefFormCard from "../MarketingBriefFormCard/MarketingBriefFormCard";
import PerformanceMetricsCard from "../PerformanceMetricsCard/PerformanceMetricsCard";
import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import useCampaignQueryChat from "./useCampaignQueryChat";
import MarketSentimentCard from "../MarketSentimentCard/MarketSentimentCard";
import CompetitorAnalysisCard from "../CompetitorAnalysisCard/CompetitorAnalysisCard";
import CompetitorStrategies from "../CompetitorStrategies/CompetitorStrategies";
import RecommendedMarketingChannels from "../RecommendedMarketingChannels/RecommendedMarketingChannels";
import Recommended5GOfferingsCard from "../Recommended5GOfferingsCard/Recommended5GOfferingsCard";
import TargetAudienceAndEstimatedNumbersCard from "../TargetAudienceAndEstimatedNumbersCard/TargetAudienceAndEstimatedNumbersCard";
import ProjectedROICard from "../ProjectedROICard/ProjectedROICard";
import BudgetAllocationAndBreakdown from "../BudgetAllocationAndBreakdown/BudgetAllocationAndBreakdown";

import AverageChannelPerformance from "../AverageChannelPerformance/AverageChannelPerformance";
import GenerateContentButton from "./GenerateContentButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useCampaignAnalysisQueryChat } from "../../context/CampaignAnalysisQueryChatContext";
import { SyncLoader } from "react-spinners";

import PinAllAndContinueCompetitorButton from "./componenets/PinAllAndContinueCompetitorButton";
import { toast } from "react-toastify";
import PinAllAndContinueSegmentsButton from "./componenets/PinAllAndContinueSegmentsButton";
import PinAllPerformanceInsights from "./componenets/PinAllPerformanceInsights";
import PinAllMarketSentiments from "./componenets/PinAllMarketSentiments";
import AudienceSegmentation from "../AudienceSegmentationCards/AudienceSegmentation";
import { updateLoadedStateForAnalysis } from "../../redux/campaignPlanAnalysisQueryChat";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CampaignQueryChatProps {
  className?: string;
}

const CampaignQueryChat: React.FC<CampaignQueryChatProps> = (props) => {
  const [userInput, setUserInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState(false);
  const [activeAnalysisPhase, setActiveAnalysisPhase] = useState(0);

  const { marketSentiments, competitors } = useCampaignQueryChat();
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const selectedCampaignRef = useRef(selectedCampaign);

  const loadedStateForAnalysisRef = useRef(loadedStateForAnalysis);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const {
    conversation,
    updateConversation,
    fetchSegmentsWithCallback,
    fetchBudgetAllocationForDifferentChannelsWithCallback,
    fetchPastCampaignAnalysisWithCallback,
  } = useCampaignAnalysisQueryChat();

  // References for scrolling
  const performanceRef = useRef<HTMLDivElement>(null);
  const audienceRef = useRef<HTMLDivElement>(null);
  const marketRef = useRef<HTMLDivElement>(null);

  const competitorRef = useRef<HTMLDivElement>(null);
  const recommendationRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const loadingRef = useRef<HTMLDivElement>(null);

  const handleCreateRecommendation = async () => {
    setLoading(true);
    fetchBudgetAllocationForDifferentChannelsWithCallback(
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: <GenerateContentButton />,
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ProjectedROICard
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={5000}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <BudgetAllocationAndBreakdown
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={4000}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <RecommendedMarketingChannels
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={3000}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <Recommended5GOfferingsCard
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={2000}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <TargetAudienceAndEstimatedNumbersCard
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={1000}
              />
            ),
          },

          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={recommendationRef}
                message="Drawing from our previous insights, I've crafted a focused plan to ensure the success of the campaign. This strategy, tailored to meet the needs of our target audience, leverages specific marketing channels to drive engagement and maximize ROI. Here's how I intend to make the campaign a resounding success. 👇🏻"
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(5);
        setLoading(false);
        setTimeout(() => {
          dispatch(
            updateLoadedStateForAnalysis({
              ...loadedStateForAnalysisRef.current,
              recommendation: true,
            })
          );
        }, 5000);
      },
      () => {}
    );
  };

  const handleShowCompetitorComparisons = async () => {
    setLoading(true);
    setTimeout(() => {
      updateConversation([
        ...competitors.map((c, index) => ({
          type: MessageType.QUESTION,
          content: (
            <CompetitorAnalysisCard
              className={styles["card"]}
              id={c.id}
              cardType="default"
              dummyLoaderDuration={4000 - (index + 1) * 1000}
            />
          ),
        })),
      ]);
      setLoading(false);
    }, 1000);
  };

  const handleCreateCompetitorAnalysis = async () => {
    setLoading(true);
    setTimeout(() => {
      updateConversation([
        {
          type: MessageType.ANSWER,
          content: (
            <div className={styles["pin-generate-row"]}>
              <Button
                className={classNames(
                  styles["card"],
                  styles["continue-button"]
                )}
                onClick={handleCreateRecommendation}
              >
                <TextElement>Generate Recommendation 👍🏻</TextElement>
              </Button>
            </div>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <div
              className={classNames(styles["card"], styles["comparison-row"])}
            >
              <PinAllAndContinueCompetitorButton
                className={classNames(styles["continue-button"])}
              />
              <Button
                className={styles["comparison-details-btn"]}
                onClick={handleShowCompetitorComparisons}
              >
                See Comparison Details
              </Button>
            </div>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <CompetitorStrategies
              className={styles["card"]}
              cardType="pinable"
              dummyLoaderDuration={3000}
            />
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              ref={competitorRef}
              message="I found these businesses to have similar offerings as yours, and here’s what they’ve been doing 👇🏻"
            />
          ),
        },
      ]);
      setActiveAnalysisPhase(4);
      setLoading(false);
      setTimeout(() => {
        dispatch(
          updateLoadedStateForAnalysis({
            ...loadedStateForAnalysisRef.current,
            competitors: true,
          })
        );
      }, 3000);
    }, 1000);
  };

  const handleCreateMarketSentiments = async () => {
    updateConversation([
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={marketRef}
            message="As we gear up to launch our campaign, understanding current market sentiments and past campaign performance is pivotal for crafting a targeted strategy that resonates with businesses and consumers alike. Here are some market sentiments that I found relevant to your campaign 👇🏻"
          />
        ),
      },
    ]);
    setLoading(true);
    setTimeout(() => {
      updateConversation([
        {
          type: MessageType.ANSWER,
          content: (
            <div
              className={classNames(styles["card"], styles["pin-generate-row"])}
            >
              <PinAllMarketSentiments
                className={classNames(styles["continue-button"])}
              />
              <Button
                className={styles["continue-button"]}
                onClick={handleCreateCompetitorAnalysis}
              >
                <TextElement>Generate Competitor Analysis 👍🏻</TextElement>
              </Button>
            </div>
          ),
        },
        ...marketSentiments.map((ms, index) => ({
          type: MessageType.QUESTION,
          content: (
            <MarketSentimentCard
              className={styles["card"]}
              id={ms.id}
              cardType="pinable"
              dummyLoaderDuration={5000 - (index + 1) * 1000}
            />
          ),
        })),
      ]);
      setLoading(false);
      setActiveAnalysisPhase(3);
      setTimeout(() => {
        dispatch(
          updateLoadedStateForAnalysis({
            ...loadedStateForAnalysisRef.current,
            marketSentiments: true,
          })
        );
      }, 5000);
    }, 1000);
  };

  const handleCreateAudienceSegment = async () => {
    updateConversation([
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={audienceRef}
            message="Based on your past campaign performance, we've identified key audience segments that we believe will be particularly relevant for your upcoming campaign. Understanding the unique needs and preferences of these segments will be crucial in crafting targeted messaging and communication strategies. 👇🏻"
          />
        ),
      },
    ]);
    setLoading(true);
    fetchSegmentsWithCallback(
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateMarketSentiments}
                >
                  <TextElement>Generate Market Sentiments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(styles["card"], styles["comparison-row"])}
              >
                <PinAllAndContinueSegmentsButton
                  className={classNames(styles["continue-button"])}
                />
                {/* ˝ */}
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <AudienceSegmentation
                className={styles["card"]}
                cardType="pinable"
              />
            ),
          },
        ]);
        setLoading(false);
        setActiveAnalysisPhase(2);
        setTimeout(() => {
          dispatch(
            updateLoadedStateForAnalysis({
              ...loadedStateForAnalysisRef.current,
              audienceSegments: true,
            })
          );
        }, 4000);
      },
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateMarketSentiments}
                >
                  <TextElement>Generate Market Sentiments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={audienceRef}
                message="Something went wrong while generating audience segments."
              />
            ),
          },
        ]);
        setLoading(false);
        setActiveAnalysisPhase(2);
      }
    );
  };

  const showPerformanceInsights = () => {
    setLoading(true);
    fetchPastCampaignAnalysisWithCallback(
      (res) => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <PinAllPerformanceInsights
                  className={classNames(styles["continue-button"])}
                />
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateAudienceSegment}
                >
                  <TextElement>Generate Audience Segments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Need more insights? Feel free to ask in the text box below 👇🏻 or click to continue for further guidance on your marketing plan. "
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <AverageChannelPerformance
                className={styles["card"]}
                cardType="pinable"
                dummyLoaderDuration={7000}
              />
            ),
          },
          ...res?.analysis?.map((insight, index) => ({
            type: MessageType.ANSWER,
            content: (
              <PerformanceMetricsCard
                className={styles["card"]}
                title={insight.metric}
                rate={insight.value}
                description={insight.definition}
                id={index}
                cardType="pinable"
                key={index}
                dummyLoaderDuration={7000 - (index + 1) * 1000}
              />
            ),
          })),
        ]);
        setActiveAnalysisPhase(1);
        setLoading(false);
        setTimeout(() => {
          dispatch(
            updateLoadedStateForAnalysis({
              ...loadedStateForAnalysisRef.current,
              pastCampaignAnalysis: true,
            })
          );
        }, 8000);
      },
      () => {}
    );
  };

  const handleCreateCampaign = async () => {
    updateConversation([
      {
        type: MessageType.QUESTION,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message="Thanks for submitting your campaign details. Let me whip up some insights for you..."
          />
        ),
      },
    ]);
    setLoading(true);
    setTimeout(() => {
      updateConversation([
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              ref={performanceRef}
              message="Based on the campaign brief, here are the industry benchmarks to guide our strategy for the upcoming campaign. These benchmarks will help us set realistic targets and optimize our approach for better results."
            />
          ),
        },
      ]);
      showPerformanceInsights();
      setSubmittedFormData(true);
      setShowInput(true);
      setLoading(false);
    }, 1000);
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
        window.scrollTo(0, 0);
      }
    }, 100);
  };

  useEffect(() => {
    if (activeAnalysisPhase === 1) {
      scrollToRef(performanceRef);
    } else if (activeAnalysisPhase === 2) {
      scrollToRef(audienceRef);
    } else if (activeAnalysisPhase === 3) {
      scrollToRef(marketRef);
    } else if (activeAnalysisPhase === 4) {
      scrollToRef(competitorRef);
    } else if (activeAnalysisPhase === 5) {
      scrollToRef(recommendationRef);
    }
  }, [activeAnalysisPhase]);

  useEffect(() => {
    loadedStateForAnalysisRef.current = loadedStateForAnalysis;
  }, [loadedStateForAnalysis]);

  useEffect(() => {
    if (!selectedCampaign && !submittedFormData) {
      updateConversation(
        [
          {
            type: MessageType.QUESTION,
            content: (
              <MarketingBriefFormCard
                className={styles["card"]}
                onSubmit={() => {
                  handleCreateCampaign();
                }}
                onErrorMessage={(message) => {
                  toast.error(message);
                }}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="To start off with your campaign, please let me know details about your campaign below. 👇🏻"
              />
            ),
          },
        ],
        true
      );
    } else {
      setShowInput(true);
    }
    selectedCampaignRef.current = selectedCampaign;
  }, [selectedCampaign]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  return (
    <>
      <div className={styles["container"]} ref={chatContainerRef}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}

          {conversation?.map((item: Question, index: number) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>
      {showInput && (
        <div className={styles["input-container"]}>
          <input />
          <SendIcon />
        </div>
      )}
    </>
  );
};

export default CampaignQueryChat;
