import React from "react";

const TargetBoardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.525 25.3667 0 22.7667 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.525 17.2333 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40ZM20 36C24.4667 36 28.25 34.45 31.35 31.35C34.45 28.25 36 24.4667 36 20C36 15.5333 34.45 11.75 31.35 8.65C28.25 5.55 24.4667 4 20 4C15.5333 4 11.75 5.55 8.65 8.65C5.55 11.75 4 15.5333 4 20C4 24.4667 5.55 28.25 8.65 31.35C11.75 34.45 15.5333 36 20 36ZM20 32C16.6667 32 13.8333 30.8333 11.5 28.5C9.16667 26.1667 8 23.3333 8 20C8 16.6667 9.16667 13.8333 11.5 11.5C13.8333 9.16667 16.6667 8 20 8C23.3333 8 26.1667 9.16667 28.5 11.5C30.8333 13.8333 32 16.6667 32 20C32 23.3333 30.8333 26.1667 28.5 28.5C26.1667 30.8333 23.3333 32 20 32ZM20 28C22.2 28 24.0833 27.2167 25.65 25.65C27.2167 24.0833 28 22.2 28 20C28 17.8 27.2167 15.9167 25.65 14.35C24.0833 12.7833 22.2 12 20 12C17.8 12 15.9167 12.7833 14.35 14.35C12.7833 15.9167 12 17.8 12 20C12 22.2 12.7833 24.0833 14.35 25.65C15.9167 27.2167 17.8 28 20 28ZM20 24C18.9 24 17.9583 23.6083 17.175 22.825C16.3917 22.0417 16 21.1 16 20C16 18.9 16.3917 17.9583 17.175 17.175C17.9583 16.3917 18.9 16 20 16C21.1 16 22.0417 16.3917 22.825 17.175C23.6083 17.9583 24 18.9 24 20C24 21.1 23.6083 22.0417 22.825 22.825C22.0417 23.6083 21.1 24 20 24Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default TargetBoardIcon;
