import React, { useEffect, useState } from "react";

import Card, { CardType } from "../Card/Card";

import styles from "./CompetitorAnalysisCard.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useCampaignQueryChat from "../CampaignQueryChat/useCampaignQueryChat";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface CompetitorAnalysis {
  id: number;
  companyImageUrl: string;
  heading: string;
  companyName: string;
  items: {
    heading: string;
    text: string;
  }[];
}

interface Props {
  className?: string;
  id?: number | string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const CompetitorAnalysisCard: React.FC<Props> = (props) => {
  const { className, id, cardType, dummyLoaderDuration } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );
  const { competitors } = useCampaignQueryChat();
  const [competitorAnalysis, setCompetitorAnalysis] =
    useState<CompetitorAnalysis | null>(null);

  useEffect(() => {
    if (competitors && id) {
      const competitor = competitors.find((competitor) => competitor.id === id);
      if (competitor) setCompetitorAnalysis(competitor);
    }
  }, [id, selectedCampaign?.includedData?.competitorIds]);

  const handlePin = (isPinned: boolean) => {
    if (!selectedCampaign) return;
    if (!selectedCampaign?.includedData) return;
    let updateCompetitorIds =
      [...selectedCampaign?.includedData?.competitorIds] || [];
    if (isPinned) {
      updateCompetitorIds.push(id as number);
    } else if (id && updateCompetitorIds.includes(id as number)) {
      updateCompetitorIds = updateCompetitorIds.filter(
        (competitorId) => competitorId !== id
      );
    }

    dispatch(
      updateCampaign({
        ...selectedCampaign,
        includedData: {
          ...selectedCampaign.includedData,
          competitorIds: Array.from(new Set(updateCompetitorIds)),
        },
      })
    );
  };
  return (
    <Card
      type={cardType}
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.competitors ? undefined : dummyLoaderDuration
      }
    >
      <div
        className={classNames(
          styles["heading-container"],
          styles["company-info"]
        )}
      >
        <div className={styles["company-meta-info"]}>
          <TextElement className={styles["title"]}>
            {competitorAnalysis?.heading}
          </TextElement>
          <TextElement className={styles["company-name"]}>
            {competitorAnalysis?.companyName}
          </TextElement>
        </div>
        <img
          className={styles["logo"]}
          src={competitorAnalysis?.companyImageUrl}
        />
      </div>
      <ul>
        {competitorAnalysis?.items.map((item, index) => (
          <li key={index}>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>{item.heading} : </span>
              {item.text}
            </TextElement>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default CompetitorAnalysisCard;
