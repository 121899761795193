import React, { useEffect } from "react";
import styles from "../ShowCampaignAnalysisData.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchPastCampaignAnalysis } from "../../../redux/campaignPlanAnalysisQueryChat";
import PerformanceMetricsCard from "../../PerformanceMetricsCard/PerformanceMetricsCard";

interface ShowPastInsightsProps {}

const ShowPastInsights: React.FC<ShowPastInsightsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { pastCampaignAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchPastCampaignAnalysis({
          campaignPlanId: selectedCampaign?.id,
        })
      );
  }, []);

  return (
    <div className={styles["campaign-data"]}>
      {selectedCampaign &&
        pastCampaignAnalysis
          .filter((_, index) =>
            selectedCampaign?.includedData?.pastInsights?.includes(index)
          )
          .map((analysis, index) => (
            <PerformanceMetricsCard
              title={analysis.metric}
              rate={analysis.value}
              description={analysis.definition}
              id={index}
              key={index}
              cardType="ellipses"
            />
          ))}
      {/* {selectedCampaign &&
          selectedCampaign?.includedData?.pastInsights
            ?.averageChannelPerformance && (
            <AverageChannelPerformance cardType="ellipses" />
          )} */}
    </div>
  );
};

export default ShowPastInsights;
