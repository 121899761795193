import React, { useEffect, useState } from "react";

import styles from "./ShowCampaignContentData.module.scss";

import { useAppSelector } from "../../redux/hooks";
import DisclosureComponent from "../Disclosure/Disclosure";
import TextElement from "../TextElement/TextElement";

import GoogleAdsCard from "../GoogleAdsCard/GoogleAdsCard";
import BlogPostContentCard from "../BlogPostContentCard/BlogPostContentCard";
import EmailMarketingContentCard from "../EmailMarketingContentCard/EmailMarketingContentCard";
import SocialMediaContentCard from "../SocialMediaContentCard/SocialMediaContentCard";
import classNames from "classnames";

interface Props {
  className?: string;
  onClick?: () => void;
}

const ShowCampaignContentData: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  useEffect(() => {
    // console.log(selectedCampaign);
  }, [selectedCampaign]);

  return (
    <div className={classNames(styles["content"], className)} onClick={onClick}>
      {selectedCampaign?.includedData?.content?.googleAds && (
        <DisclosureComponent
          header={<TextElement>GOOGLE ADS</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {selectedCampaign?.includedData?.content?.googleAds && (
                <GoogleAdsCard cardType="ellipses" />
              )}
            </div>
          }
        />
      )}
      {selectedCampaign?.includedData?.content?.contentMarketing && (
        <DisclosureComponent
          header={<TextElement>CONTENT MARKETING</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {selectedCampaign?.includedData?.content?.contentMarketing && (
                <BlogPostContentCard cardType="ellipses"/>
              )}
            </div>
          }
        />
      )}
      {selectedCampaign?.includedData?.content?.emailMarketing && (
        <DisclosureComponent
          header={<TextElement>EMAIL MARKETING</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {selectedCampaign?.includedData?.content?.emailMarketing && (
                <EmailMarketingContentCard cardType="ellipses"/>
              )}
            </div>
          }
        />
      )}
      {selectedCampaign?.includedData?.content?.socialMediaAdvertising && (
        <DisclosureComponent
          header={<TextElement>SOCIAL MEDIA ADVERTISING</TextElement>}
          content={
            <div className={styles["campaign-data"]}>
              {selectedCampaign?.includedData?.content
                ?.socialMediaAdvertising && <SocialMediaContentCard cardType="ellipses"/>}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ShowCampaignContentData;
