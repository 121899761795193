import React, { useState, useRef, useEffect } from "react";

import styles from "./CampaignContentQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import SocialMediaContentCard from "../SocialMediaContentCard/SocialMediaContentCard";
import EmailMarketingContentCard from "../EmailMarketingContentCard/EmailMarketingContentCard";
import BlogPostContentCard from "../BlogPostContentCard/BlogPostContentCard";
import GoogleAdsCard from "../GoogleAdsCard/GoogleAdsCard";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PinAllAndContinueButton from "./components/PinAllAndContinueButton";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CampaignContentQueryChatProps {
  className?: string;
}

const CampaignContentQueryChat: React.FC<CampaignContentQueryChatProps> = (
  props
) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleCreateContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: <PinAllAndContinueButton />,
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message="Happy with all the content generated? You can pin all and submit for approval."
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <SocialMediaContentCard
            className={styles["card"]}
            cardType="pinable"
            dummyLoaderDuration={4000}
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <EmailMarketingContentCard
            className={styles["card"]}
            cardType="pinable"
            dummyLoaderDuration={3000}
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <BlogPostContentCard
            className={styles["card"]}
            cardType="pinable"
            dummyLoaderDuration={2000}
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <GoogleAdsCard
            className={styles["card"]}
            cardType="pinable"
            dummyLoaderDuration={1000}
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message="Let's put this marketing plan into action! Allow me to craft some compelling content for you... ✨"
          />
        ),
      },
      ...pC,
    ]);
  };

  const [conversation, setConversation] = useState<Question[]>([]);

  useEffect(() => {
    handleCreateContent();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {conversation?.map((item: Question, index: number) => (
            <div key={index}>{item.content}</div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default CampaignContentQueryChat;
