import React from "react";

import styles from "./BlogPostContentCard.module.scss";

import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface BlogPostContentCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const BlogPostContentCard: React.FC<BlogPostContentCardProps> = (props) => {
  const { className, cardType, dummyLoaderDuration } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const handlePinned = (isPinned: boolean) => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            content: {
              ...selectedCampaign?.includedData?.content,
              contentMarketing: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
      dummyLoaderDuration={dummyLoaderDuration}
      type={cardType}
    >
      <TextElement className={styles["heading"]}>
        Content Marketing (Blog Post)
      </TextElement>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Title</TextElement>
        <div className={styles["content"]}>
          <TextElement>
            {" "}
            The Future is Here: How Salesforce Managed Services Can Transform Your Business
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Introduction
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Welcome to the era of 5G—the next-generation technology that
            promises to revolutionize the way businesses operate. With speeds up
            to 10 times faster than 4G and ultra-low latency, 5G opens up a
            world of possibilities for businesses across industries. In this
            blog post, we'll explore the transformative potential of 5G
            technology and how it can propel your business forward.
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Introduction
        </TextElement>
        <div className={styles["content"]}>
          <ol>
            <li>
              <TextElement>Lightning-Fast Speeds:</TextElement>
              <ul>
                <li>
                  Say goodbye to buffering and slow downloads. With 5G,
                  businesses can enjoy lightning-fast speeds that enable
                  seamless data transfers and real-time communication. Whether
                  you're uploading large files or conducting video conferences,
                  5G ensures a smooth and efficient experience.
                </li>
              </ul>
            </li>
            <li>
              <TextElement>Real-Time Communication:</TextElement>
              <ul>
                <li>
                  5G's ultra-low latency of less than 1 millisecond enables
                  real-time communication and collaboration like never before.
                  Businesses can connect with customers and colleagues
                  instantly, leading to faster decision-making and improved
                  productivity.
                </li>
              </ul>
            </li>
            <li>
              <TextElement>Enhanced Connectivity:</TextElement>
              <ul>
                <li>
                  With 5G's support for up to 1 million connected devices per
                  square kilometer, businesses can create interconnected
                  ecosystems that drive operational efficiencies and unlock new
                  revenue streams. From IoT devices to smart infrastructure, 5G
                  enables seamless connectivity across all aspects of business
                  operations.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <TextElement className={styles["content-heading"]}>
          Conclusion
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            As the 5G revolution unfolds, businesses can seize the chance to
            fully utilize this game-changing technology. With its faster speeds
            and real-time communication capabilities, 5G is set to overhaul
            industries and spur innovation. Get ahead and embrace the future
            with 5G.
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Call to Action
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Ready to unlock the power of 5G for your business? Contact us today
            to learn more about our 5G solutions and how they can benefit your
            organization.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default BlogPostContentCard;
