import React from "react";

import styles from "./CompetitorStrategies.module.scss";
import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import PricingTagIcon from "../../icons/PricingTagIcon";
import TextElement from "../TextElement/TextElement";
import FocusIcon from "../../icons/FocusIcon";
import PartnershipIcon from "../../icons/PartnershipIcon";
import TargetBoardIcon from "../../icons/TargetBoardIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const CompetitorStrategies: React.FC<Props> = (props) => {
  const { className, cardType, dummyLoaderDuration } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            competitorStrategies: isPinned,
          },
        })
      );
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.competitors ? undefined : dummyLoaderDuration
      }
      type={cardType}
    >
      <TextElement className={styles["heading"]}>
        Strategies to Beat Your Competitors
      </TextElement>
      <div className={styles["strategy"]}>
        <PricingTagIcon />
        <TextElement>
          Offer competitive pricing, bundle deals, or unique content
          partnerships to attract customers.
        </TextElement>
      </div>
      <div className={styles["strategy"]}>
        <FocusIcon />
        <TextElement>
          Focus on exceptional service, personalized experiences, and network
          reliability to differentiate.
        </TextElement>
      </div>
      <div className={styles["strategy"]}>
        <PartnershipIcon />
        <TextElement>
          Showcase innovative solutions, partnerships, or technologies to
          demonstrate leadership in the 5G space.
        </TextElement>
      </div>
      <div className={styles["strategy"]}>
        <TargetBoardIcon />
        <TextElement>
          Utilize targeted digital campaigns and partnerships to effectively
          reach specific customer segments.
        </TextElement>
      </div>
    </Card>
  );
};

export default CompetitorStrategies;
