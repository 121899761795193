import React, { useEffect } from "react";
import ListingCard from "../ListingCard/ListingCard";

import styles from "./common.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import classNames from "classnames";
import Card, { CardType } from "../Card/Card";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import ShowMoreLess from "../ShowMoreLess/ShowMoreLess";
import TextElement from "../TextElement/TextElement";

interface Props {
  className?: string;
  cardType: CardType;
  segmentationId?: number;
  dummyLoaderDuration?: number;
}

const AudienceSegmentation: React.FC<Props> = (props) => {
  const { className, cardType, segmentationId, dummyLoaderDuration } = props;

  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  const dispatch = useAppDispatch();

  const { audienceSegments, loading, error } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [audienceSegmentById, setAudienceSegmentById] = React.useState<{
    [key: string]: string;
    Industry: string;
  }>({
    Industry: "",
  });

  useEffect(() => {
    if (segmentationId !== undefined) {
      setAudienceSegmentById(audienceSegments[segmentationId] as any);
    }
  }, [audienceSegments, segmentationId]);

  const handlePin = (isPinned: boolean, id: number) => {
    if (!selectedCampaign) return null;
    if (!isPinned) {
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            audienceSegmentation:
              selectedCampaign.includedData.audienceSegmentation.filter(
                (segmentId) => segmentId !== id
              ),
          },
        })
      );
    } else {
      if (selectedCampaign.includedData.audienceSegmentation.includes(id))
        return;
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            audienceSegmentation: [
              ...selectedCampaign.includedData.audienceSegmentation,
              id,
            ],
          },
        })
      );
    }
  };

  if (segmentationId !== undefined) {
    const {
      Content: content,
      Industry: industry,
      "Names of All Companies": nameOfAllCompanies,
      "Number of All Companies": companiesLength,
      "Number of Companies Using Salesforce": salesForceUsers,
      "Number of Companies Hiring for Salesforce Related Positions":
        hiringPositions,
      "Company Revenue": revenue,
      "Company Size": size,
      State: state,
      Relevance: relevance,
      ...rest
    } = audienceSegmentById as {
      [key: string]: string;
    };
    return (
      <Card
        className={classNames(styles["segment"], className)}
        type={cardType}
        onPin={() => handlePin(true, segmentationId)}
        unPin={() => handlePin(false, segmentationId)}
        dummyLoaderDuration={loading ? dummyLoaderDuration : undefined}
      >
        <TextElement className={styles["segment-heading"]}>
          Segment {String.fromCharCode(65 + segmentationId)} Overview
        </TextElement>
        <TextElement className={classNames(styles["segment-industry"])}>
          Industry: {industry}
        </TextElement>
        <div className={styles["segment-overview"]}>
          <div className={classNames(styles["segment-overview-item"])}>
            <TextElement className={classNames(styles["heading"])}>
              Company Size
            </TextElement>
            <TextElement className={classNames(styles["value"])}>
              {size}
            </TextElement>
          </div>
          <div className={classNames(styles["segment-overview-item"])}>
            <TextElement className={classNames(styles["heading"])}>
              Revenue Range
            </TextElement>
            <TextElement className={classNames(styles["value"])}>
              {revenue}
            </TextElement>
          </div>
          <div className={classNames(styles["segment-overview-item"])}>
            <TextElement className={classNames(styles["heading"])}>
              Location
            </TextElement>
            <TextElement className={classNames(styles["value"])}>
              {state}
            </TextElement>
          </div>
        </div>
        <div className={styles["segments-statisitcs"]}>
          <TextElement className={classNames(styles["item"])}>
            Total Companies:
            <span>&nbsp; {companiesLength}</span>
          </TextElement>
          <TextElement className={classNames(styles["item"])}>
            Salesforce Users:
            <span>&nbsp; {salesForceUsers}</span>
          </TextElement>
          <TextElement className={classNames(styles["item"])}>
            Currently Hiring for Salesforce:
            <span>&nbsp; {hiringPositions}</span>
          </TextElement>
          <TextElement className={classNames(styles["item"])}>
            Relevance:
            <span>&nbsp; {relevance}</span>
          </TextElement>
          <TextElement className={classNames(styles["item"])}>
            Companies
            <span>
              &nbsp;
              <ShowMoreLess
                text={
                  Array.isArray(nameOfAllCompanies)
                    ? nameOfAllCompanies.join(", ")
                    : nameOfAllCompanies
                }
                maxWords={10}
              />
            </span>
          </TextElement>
        </div>
      </Card>
    );
  }

  return (
    <div className={styles["card-container"]}>
      {audienceSegments?.map((segment, index) => {
        const {
          Content: content,
          Industry: industry,
          "Names of All Companies": nameOfAllCompanies,
          "Number of All Companies": companiesLength,
          "Number of Companies Using Salesforce": salesForceUsers,
          "Number of Companies Hiring for Salesforce Related Positions":
            hiringPositions,
          "Company Revenue": revenue,
          "Company Size": size,
          State: state,
          Relevance: relevance,
          ...rest
        } = segment as {
          [key: string]: string;
        };
        return (
          <Card
            className={classNames(styles["segment"], className)}
            type={cardType}
            onPin={() => handlePin(true, index)}
            unPin={() => handlePin(false, index)}
            dummyLoaderDuration={
              loadedStateForAnalysis.audienceSegments ? undefined : index * 1000
            }
          >
            <TextElement className={styles["segment-heading"]}>
              Segment {String.fromCharCode(65 + index)} Overview
            </TextElement>
            <TextElement className={classNames(styles["segment-industry"])}>
              Industry: {industry}
            </TextElement>
            <div className={styles["segment-overview"]}>
              <div className={classNames(styles["segment-overview-item"])}>
                <TextElement className={classNames(styles["heading"])}>
                  Company Size
                </TextElement>
                <TextElement className={classNames(styles["value"])}>
                  {size}
                </TextElement>
              </div>
              <div className={classNames(styles["segment-overview-item"])}>
                <TextElement className={classNames(styles["heading"])}>
                  Revenue Range
                </TextElement>
                <TextElement className={classNames(styles["value"])}>
                  {revenue}
                </TextElement>
              </div>
              <div className={classNames(styles["segment-overview-item"])}>
                <TextElement className={classNames(styles["heading"])}>
                  Location
                </TextElement>
                <TextElement className={classNames(styles["value"])}>
                  {state}
                </TextElement>
              </div>
            </div>
            <div className={styles["segments-statisitcs"]}>
              <TextElement className={classNames(styles["item"])}>
                Total Companies:
                <span>&nbsp; {companiesLength}</span>
              </TextElement>
              <TextElement className={classNames(styles["item"])}>
                Salesforce Users:
                <span>&nbsp; {salesForceUsers}</span>
              </TextElement>
              <TextElement className={classNames(styles["item"])}>
                Currently Hiring for Salesforce:
                <span>&nbsp; {hiringPositions}</span>
              </TextElement>
              <TextElement className={classNames(styles["item"])}>
                Relevance:
                <span>&nbsp; {relevance}</span>
              </TextElement>
              <TextElement className={classNames(styles["item"])}>
                Companies:
                <span>
                  &nbsp;
                  <ShowMoreLess
                    text={
                      Array.isArray(nameOfAllCompanies)
                        ? nameOfAllCompanies.join(", ")
                        : nameOfAllCompanies
                    }
                    maxWords={10}
                  />
                </span>
              </TextElement>
            </div>
          </Card>
        );
        return (
          <ListingCard
            className={classNames(styles["card"], className)}
            listHeading={`Segment ${String.fromCharCode(
              65 + index
            )}: ${industry}`}
            key={index}
            description={content}
            list={
              rest
                ? Object.entries(rest).map(([key, value]) => {
                    if (
                      key === "Names of All Companies" ||
                      key === "Names of Companies Using Salesforce"
                    )
                      return {
                        heading: key,
                        text: (
                          <ShowMoreLess
                            text={
                              Array.isArray(value) ? value.join(", ") : value
                            }
                            maxWords={10}
                          />
                        ),
                      };
                    return {
                      heading: key,
                      text: value,
                    };
                  })
                : []
            }
            onPin={() => handlePin(true, index)}
            unPin={() => handlePin(false, index)}
            dummyLoaderDuration={
              loadedStateForAnalysis.audienceSegments ? undefined : index * 1000
            }
            cardType={cardType}
          />
        );
      })}
    </div>
  );
};

export default AudienceSegmentation;
