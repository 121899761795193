import React from "react";

import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import TargetAudienceIcon from "../../icons/TargetAudienceIcon";

import styles from "./TargetAudienceAndEstimatedNumbersCard.module.scss";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface TargetAudienceAndEstimatedNumbersCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
}

const TargetAudienceAndEstimatedNumbersCard: React.FC<
  TargetAudienceAndEstimatedNumbersCardProps
> = (props) => {
  const { className, dummyLoaderDuration, cardType } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              targetAudienceAndEstimatedNumbers: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      type={cardType}
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      dummyLoaderDuration={
        loadedStateForAnalysis?.recommendation ? undefined : dummyLoaderDuration
      }
    >
      <TextElement className={styles["heading"]}>
        Target Audience and Estimated Numbers
      </TextElement>
      <div className={styles["audience-info"]}>
        <TargetAudienceIcon />
        <ul>
          {[
            {
              heading: "Tech Enthusiasts",
              text: "Estimated audience of 100,000 individuals.",
            },
            {
              heading: "Innovators and Creators",
              text: "Estimated audience of 75,000 individuals.",
            },
            {
              heading: "Community Builders",
              text: "Estimated audience of 50,000 individuals.",
            },
          ].map((item, index) => (
            <li key={index}>
              <TextElement className={styles["text"]}>
                <span className={styles["heading"]}>{item.heading} : </span>
                {item.text}
              </TextElement>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default TargetAudienceAndEstimatedNumbersCard;
